
import {defineComponent} from 'vue'
import {TranslateResult} from 'vue-i18n'
import {mapGetters} from 'vuex';
import {bus} from '@/bootstrap/bus'
import {CountEvent} from '@/types/StockCounts'
import {CountTask} from "@/types/CountTasks";
import extractQrElements from '@/utilities/ScanHandlers'
import ProductTile from '@/components/pages/CycleCounting/ProductTile.vue';
import { StockForLocation, ProductAttributes } from '@/types/Stock';
import { skuHasBatchedCount } from '@/store/cycleCounting/helpers';

export default defineComponent({
  name: "ProductCount",
  components: {
    ProductTile,
  },
  data(): {
    activeTask: CountTask | null,
    sku: string | null,
    batch: string | null,
    count: string | null,
    startTime: string,
    error: TranslateResult | null,
    showBatchInput: boolean,
    inputMode: 'manual' | 'scan',
    scanAccepted: boolean,
    countInputError: TranslateResult | null,
    productDetails: Partial<StockForLocation['attributes']> | null,
    inProgress: boolean
  } {
    return {
      activeTask: null,
      sku: null,
      batch: null,
      count: null,
      startTime: new Date().toISOString(),
      error: null,
      showBatchInput: true,
      inputMode: 'scan',
      scanAccepted: false,
      countInputError: null,
      productDetails: null,
      inProgress: false
    };
  },
  computed: {
    locationName(): string {
      return this.$store.getters['cycleCounting/getLocation']
    },
    // For now exclude batch, until we know that the client is enabled and the product is batched
    canSubmit(): boolean {
      if (!this.sku || !this.count || !this.isInteger(this.count)) {
        return false
      }
      const count = parseInt(this.count)
      return count >= 0 && count <= Number.MAX_SAFE_INTEGER
    },
    showBatch(): boolean {
      return this.showBatchInput
    },
    errors(): TranslateResult | null {
      return this.error
    },
    showProductInputs(): boolean {
      return this.inputMode === 'manual' || this.scanAccepted
    },
    scanMessage(): TranslateResult {
      return this.errors ?? this.$t('cycle_counting.product_count_scan_product')
    },
    ...mapGetters('cycleCounting', ['getStocksBySkuId'])
  },
  created() {
    bus.$on("barcodeScanned", this.handleItemScan);
  },
  async mounted() {
    const activeTask = await this.getActiveTask();
    if (activeTask) {
      this.activeTask = activeTask
      this.setProductDetails(activeTask.huboo_sku)
    }
  },
  beforeDestroy() {
    bus.$off("barcodeScanned", this.handleItemScan);
  },
  methods: {
    findStockBatchRef(batchId: number): string {
      const foundStock: StockForLocation = this.$store.getters['cycleCounting/getStocks']
        .find((stock: StockForLocation) => stock.attributes.batch?.id === batchId);
      return foundStock?.attributes.batch?.reference ?? 'Reference not found'
    },
    saveCountToStore(): void {
      const payload: CountEvent = {
        sku:  Number(this.sku),
        batch_id: this.batch ? Number(this.batch) : null,
        batch_ref: this.batch ? this.findStockBatchRef(Number(this.batch)) : null,
        counted_quantity: Number(this.count),
        system_quantity: 0,
        task_id: this.activeTask?.id ?? null,
        client_id: null,
        time: {
          start: this.startTime,
          end: new Date().toISOString()
        }
      }

      this.$store.commit('cycleCounting/addCountEvent', payload)
    },
    saveAndNext(): void {
      this.saveCountToStore()
      this.reset()
      this.startTime = new Date().toISOString()
    },
    save(): void {
      this.saveCountToStore()
      this.$store.dispatch('core/redirect', {name: 'count_summary'})
    },
    async handleItemScan(scanResult: string): Promise<void> {
      this.reset()
      let [sku = null, batchId = null] = extractQrElements(scanResult);
      const isQrCode = (sku !== null || batchId !== null);
      // Check the store to see if we can get batch status
      const batched = skuHasBatchedCount(this.$store.state.cycleCounting, this.activeTask?.huboo_sku)
      if (!isQrCode) {
        if (batched === true) {
          this.error = this.$t('batched_item_cannot_be_scanned_by_barcode');
          return;
        }

        const productSku = await this.handleBarcodeScan(scanResult)
        if (productSku === null) {
          return
        }

        sku = productSku
        // This next line has no effect but is required to stop tslint complaining on prefer-const
        batchId = null
      }
      if (this.activeTask && !this.skuInTask(Number(sku))) {
        this.error = this.$t('sku_not_matched_to_task')
        return;
      }
      if (batched === true && batchId === null) {
        this.error = this.$t('batched_item_missing_batch_association');
        return;
      }
      if (batched === false && batchId !== null) {
        this.error = this.$t('batch_scan_on_non_batch_item');
        return;
      }

      this.setProductDetails(Number(sku), Number(batchId), true)

      this.sku = sku
      this.batch = batchId
      this.showBatchInput = (batchId !== null)
      this.scanAccepted = true
    },
    reset(partial = false): void {
      this.sku = null
      this.batch = null
      this.count = null
      this.error = null
      this.showBatchInput = true
      this.scanAccepted = false
      this.countInputError = null
      if (!partial) {
        this.inputMode = 'scan'
      }
    },
    toggleInputMethod(): void {
      this.reset(true)
      this.inputMode = this.inputMode === 'manual' ? 'scan' : 'manual'
    },
    validateOnInput(): void {
      this.countInputError = null
      if (this.count && !this.isInteger(this.count)) {
        this.countInputError = this.$t('cycle_counting.positive_integer_required')
      }
    },
    isInteger(s: string): boolean {
      // must only contain digits
      return /^\d+$/.test(s)
    },
    setProductDetails(skuId: number, batchId: number | null = null, isScan = false): void {
      const foundStock: StockForLocation | null = this.$store.getters['cycleCounting/getStocks']
        .find((stock: StockForLocation) => {
          return (
            stock.attributes.sku === skuId &&
            (batchId ? stock.attributes.batch?.id === batchId : true)
          )
        });

      if (!foundStock) {
        this.productDetails = {
          sku: skuId,
          location_name: this.locationName,
        };
        return
      }

      // if this has not been scanned, dont display any batch information
      // if this is scanned, then
      // if found in store "stock" display
      // if not found in store then do round trip to populate store
      this.productDetails = {
        sku: skuId,
        batch: isScan ? foundStock.attributes.batch : null,
        location_name: this.locationName,
        title: foundStock.attributes.title,
        image_url: foundStock.attributes.image_url,
      }
    },
    async getActiveTask(): Promise<CountTask | null> {
      const activeTaskId = await this.$store.getters['cycleCounting/getActiveTaskId'];
      if (activeTaskId) {
        const allTasks = await this.$store.getters['cycleCounting/getTasks'];

        const task: CountTask = allTasks.find((task: CountTask) => task.id === activeTaskId);

        return task;
      }
      return null;
    },
    skuInTask(sku: number): boolean {
      return sku === this.activeTask?.huboo_sku;
    },
    skuEnteredManually(): void {
      this.skuInTaskValidation();

      // do look up for user manually entered batch ref
      // -> Product Look up -> is batched? -> search manually entered batch ref on SKU -> return batch id
      if(this.sku) {
        this.setProductDetails(Number(this.sku), null, true);
      }
    },
    skuInTaskValidation(): void {
      if (!this.activeTask) {
        return;
      }
      this.error = !this.skuInTask(Number(this.sku)) ? this.$t('sku_not_matched_to_task') : '';
    },
    async handleBarcodeScan(barcode: string): Promise<string|null> {
      bus.$off("barcodeScanned", this.handleItemScan);
      this.inProgress = true

      const productsWithBarcode = await this.$store.dispatch(
          'cycleCounting/getProductsForBarcode',
          {
            barcode,
            huboo_sku: this.activeTask?.huboo_sku
          }
      )

      let sku: string|null = null
      if (!productsWithBarcode || productsWithBarcode.length === 0) {
        this.error = this.$t('invalid_barcode_scan')
      }
      if (!this.error && productsWithBarcode.length > 1) {
        this.error = this.$t(
            'cycle_counting.barcode_found_on_multiple_skus',
            {
              skus: productsWithBarcode.map((item: ProductAttributes) => item.sku).join(',')
            }
          );
      }
      if (!this.error && productsWithBarcode[0].is_batched) {
        this.error = this.$t('batched_item_cannot_be_scanned_by_barcode');
      }
      if (!this.error) {
        sku = productsWithBarcode[0].sku.toString()
      }

      this.inProgress = false
      bus.$on("barcodeScanned", this.handleItemScan);

      return sku
    }
  }
})
