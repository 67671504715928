import {CountEvent} from "@/types/StockCounts";
import {StockForLocation} from "@/types/Stock";
import getters from "@/store/cycleCounting/getters";
import mutations from "@/store/cycleCounting/mutations";
import actions from "@/store/cycleCounting/actions";
import { CountTasks } from '@/types/CountTasks';

export type CycleCountingStateInterface = {
  location_name: string | null,
  countEvents: CountEvent[],
  stocks: StockForLocation[],
  isRecount: boolean,
  countTasks: CountTasks,
  activeTaskId: number | null,
  page: number,
  perPage: number,
  totalTasks: number | null,
};
export const defaultState = (): CycleCountingStateInterface => ({
  location_name: null,
  stocks: [],
  countEvents: [],
  isRecount: false,
  countTasks: [],
  activeTaskId: null,
  page: 1,
  perPage: 10,
  totalTasks: null,
});

export default {
  namespaced: true,
  state: defaultState() as CycleCountingStateInterface,
  getters,
  mutations,
  actions,
}
