import setState from "@/utilities/store";
import {CountEvent} from "@/types/StockCounts";
import {StockForLocation} from "@/types/Stock";
import {CycleCountingStateInterface, defaultState} from "@/store/cycleCounting";
import { doBatchMatchIfPresent } from './helpers';
import { CountTasks } from '@/types/CountTasks';

export default {
  resetState (state: CycleCountingStateInterface): void {
    setState(state, defaultState())
  },
  setLocation(state: CycleCountingStateInterface, location: string): void {
    state.location_name  = location;
  },
  addCountEvent(state: CycleCountingStateInterface, countEvent: CountEvent ): void {
    state.countEvents.push(countEvent);
  },
  removeCountEvent(state: CycleCountingStateInterface, index: number): void {
    state.countEvents?.splice(index, 1);
  },
  clearCountEvents(state: CycleCountingStateInterface): void {
    state.countEvents = [];
  },
  setStocks(state: CycleCountingStateInterface, stocks: StockForLocation[]): void {
    state.stocks = stocks;
  },
  clearStocks(state: CycleCountingStateInterface): void {
    state.stocks = [];
  },
  setIsRecount(state: CycleCountingStateInterface, isRecount: boolean): void {
    state.isRecount = isRecount;
  },
  setSystemQuantitiesToCountEvents(state: CycleCountingStateInterface): void {
    state.countEvents.forEach(countEvent => {
      countEvent.system_quantity = state.stocks.find(stock => {
        return (
          stock.attributes.sku === countEvent.sku && doBatchMatchIfPresent(countEvent, stock)
        )
      })?.attributes.quantity ?? 0;
    });
  },
  setCountTasks(state: CycleCountingStateInterface, countTasks: CountTasks): void {
    state.countTasks = countTasks;
  },
  setPage(state: CycleCountingStateInterface, page: number): void {
    state.page = page;
  },
  setActiveTaskId(state: CycleCountingStateInterface, taskId: number): void {
    state.activeTaskId = taskId;
  },
  setTotalTasks(state: CycleCountingStateInterface, totalTasks: number): void {
    state.totalTasks  = totalTasks;
  },
}
