import {defineStore} from "pinia";
import {computed, ref} from "vue";
import { SmartOrderMapped } from '@/types/ApiSmartOrders';

export const useOrdersStore = defineStore('OrdersStore', () => {
    const orders = ref<SmartOrderMapped[]>([]);
    const getOrderById = computed(() => (id: number) => orders.value.find((order: SmartOrderMapped) => order.id === id));

    const setOrders = (newOrders: SmartOrderMapped[]) => {
      orders.value = newOrders
    }

    return {
        orders,
        getOrderById,
        setOrders,
    }
})
