import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export { ContainerPickActionContext } from "./actions";

export type ContainerScanValidationResult = {
  failed: boolean;
  content?: { [key: string]: string };
};

export type ContainerPickState = {
  pickedStock: { id: string; quantity: number, orderLineId: number }[];
  containerScanValidation: {
    containerTypeValidation: ContainerScanValidationResult;
    containerParentValidation: ContainerScanValidationResult;
    containerStatusValidation: ContainerScanValidationResult;
    containerSkusInOrderValidation: ContainerScanValidationResult;
    quantityValidation: ContainerScanValidationResult;
    batchExpiryValidation: ContainerScanValidationResult;
    batchMinExpiryValidation: ContainerScanValidationResult;
    batchQuarantineValidation: ContainerScanValidationResult;
    previouslyPickedValidation: ContainerScanValidationResult;
    unpickableLocationValidation: ContainerScanValidationResult;
    containerHasBatchValidation: ContainerScanValidationResult;
  };
};

export default {
  namespaced: true,

  state: (): ContainerPickState => ({
    pickedStock: [],
    containerScanValidation: {
      containerTypeValidation: { failed: false },
      containerParentValidation: { failed: false },
      containerStatusValidation: { failed: false },
      containerSkusInOrderValidation: { failed: false },
      quantityValidation: { failed: false },
      batchExpiryValidation: { failed: false },
      batchMinExpiryValidation: { failed: false },
      batchQuarantineValidation: { failed: false },
      previouslyPickedValidation: { failed: false },
      unpickableLocationValidation: { failed: false },
      containerHasBatchValidation: { failed: false },
    },
  }),

  getters,
  actions,
  mutations,
};
