<template>
  <div class="mb-15">
  <v-speed-dial
      v-model="fab"
      bottom
      left
      direction="bottom"
      fixed
  >
    <template v-slot:activator>
      <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
          rounded
          @click="showDialog"
      >
        <v-icon v-if="isMarkedAsSplitPack">
          mdi-package-variant-closed
        </v-icon>
        <v-icon v-else>
          mdi-package-variant
        </v-icon>
      </v-btn>
    </template>
  </v-speed-dial>
  <v-dialog :value="confirmSplitPack"
            persistent
            max-width="500"
            v-if="dialogVisible"
  >
    <v-card>
      <v-card-title class="headline">
        <span v-if="isMarkedAsSplitPack">
          {{ $t('revert_single_package') }}
        </span>
        <span v-else>
          {{ $t('split_complete_order') }}
        </span>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="hideDialog" id="hide_split_pack_dialog">{{ $t('cancel') }}</v-btn>
        <v-btn
            color="secondary"
            id="orders_to_split_pack_dialog_submit_btn"
            @click="markAsSplitPack()"
        >
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {ORDER_STEP_ID_PACK, ORDER_STEP_ID_PICK, ORDER_STEP_ID_SPLITPACK} from "@/bootstrap/config";
import {isPackageIdentifier} from "@/utilities/CodeIdentifier";
import {bus} from "@/bootstrap/bus";

export default {
  name: "SplitPack",
  data: () => ({
    direction: 'top',
    fab: false,
    confirmSplitPack: false,
    dialogVisible: false,
  }),
  created() {
    bus.$on('barcodeScanned', this.handleBarcodeScan)
  },
  beforeDestroy() {
    bus.$off('barcodeScanned', this.handleBarcodeScan)
  },
  computed: {
    isMarkedAsSplitPack() {
      return this.$store.getters['picking/getSplitPack']
    }
  },
  methods: {
    handleBarcodeScan(barcode) {
      if (isPackageIdentifier(barcode)) {
        this.$emit('updatePackageIdentifier', barcode);
        return;
      }
    },
    showDialog() {
      this.dialogVisible = true
      this.confirmSplitPack = true
    },
    hideDialog() {
      this.dialogVisible = false
    },
    markAsSplitPack() {
      this.confirmSplitPack = false
      this.$store.commit('picking/toggleSplitPack')
      // We've just toggled it, so look for the inverse of what we want
      if (this.isMarkedAsSplitPack) {
        this.$store.dispatch('picking/setOrderStep', ORDER_STEP_ID_SPLITPACK)
        this.$store.dispatch('core/redirect', { name: 'orders' }, {root: true})
      } else {
        const step = this.$store.getters['picking/unpickedItems'].length === 0 ? ORDER_STEP_ID_PACK : ORDER_STEP_ID_PICK
        this.$store.dispatch('picking/setOrderStep', step)
      }
    },
  },
}
</script>

<style scoped>
</style>
