import {Scanners} from '@/enums/Scanners'

export class ScannerLatencyResolver {
	private device: null | string
	private readonly defaultLatency = 10

	constructor(device: string | null) {
		this.device = device
	}

	public resolve(): number {
		let latency = this.defaultLatency
		if (this.device === Scanners.GLOVE_SCANNER) {
			latency = 100
		}
		return latency
	}
}
