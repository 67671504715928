import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { Tote, WavePickingTaskAttributes } from "@/types/WavePicking";
import { BaseTask, useBaseTask } from "./base-tasks";
import { ChallengeTypes, TaskStatus, TaskTypes } from "./types";
import {
  QuantityChallengeAttributes,
  useQuantityChallenge,
} from "../challenges/quantity-challenge";

export type PickTask = BaseTask<
  WavePickingTaskAttributes,
  QuantityChallengeAttributes
>;

export const usePickStore = defineStore(
  "PickTaskStore",
  () => {
    const taskStore = useBaseTask<
      WavePickingTaskAttributes,
      QuantityChallengeAttributes
    >();
    const quantityChallengeStore = useQuantityChallenge();

    const type = ref<TaskTypes>(TaskTypes.PICK);
    const scannedTotes = ref<Tote[]>([]);

    const getTotes = computed(() => scannedTotes.value);

    function reset() {
      taskStore.actions.reset();
      scannedTotes.value = [];
    }

    function setPickingTasksAttributesOnAllTasks(
      pickTaskAttributes: WavePickingTaskAttributes
    ): void {
      taskStore.state.tasks.value.forEach((task) => {
        task.attributes = pickTaskAttributes;
      });
    }

    function setPickingTasksAttributesByTaskId(
      taskId: number,
      pickTaskAttributes: WavePickingTaskAttributes
    ): void {
      if (taskStore.state.tasks.value.length > 0) {
        const found = taskStore.getters.getTaskById.value(taskId);
        if (found) {
          found.attributes = pickTaskAttributes;
        }
      }
    }

    function setPickingTasksStatusByTaskId(
      taskId: number,
      status: TaskStatus
    ): void {
      if (taskStore.state.tasks.value.length > 0) {
        const found = taskStore.getters.getTaskById.value(taskId);
        if (found) {
          found.status = status;
        }
      }
    }

    function setPickingTasksQtyPickedByTaskId(
      taskId: number,
      qty: number
    ): void {
      if (taskStore.state.tasks.value.length > 0) {
        const found = taskStore.state.tasks.value.find(
          (task) => task.id === taskId
        );
        if (found) {
          found.attributes.quantityPicked += qty;
        }
      }
    }

    function setChallengesForAllTasks(): void {
      taskStore.state.tasks.value.forEach((task) => {
        if (task.attributes.quantity > 1) {
          task.challenges = [
            {
              id: Math.floor(Math.random() * 9999999),
              type: ChallengeTypes.QUANTITY,
              passed: false,
              attributes: {
                quantityRequired: task.attributes.quantity,
                quantityEntered: null,
              },
              errorMessages: [],
            },
          ];
        }
      });
    }

    function setChallengeStatusByTaskAndChallengeId(
      taskId: number,
      challengeId: number,
      passed: boolean
    ): void {
      if (taskStore.state.tasks.value.length > 0) {
        const foundTask = taskStore.state.tasks.value.find(
          (task) => task.id === taskId
        );
        if (foundTask) {
          const foundChallenge = foundTask.challenges.find(
            (challenge) => challenge.id === challengeId
          );
          if (foundChallenge) {
            foundChallenge.passed = passed;
          }
        }
      }
    }

    function setTotes(totes: Tote[]): void {
      scannedTotes.value = totes;
    }

    function removeTasksByTote(tote: Tote): void {
      const tasksForTote = taskStore.getters.getTasks.value.filter(
        (task) => task.attributes.toteName === tote.name
      );
      tasksForTote.forEach((task) => {
        taskStore.actions.removeTaskById(task.id);
      });
    }

    function removeTote(tote: Tote): void {
      const idx = scannedTotes.value.findIndex((t) => t.name === tote.name);
      // TODO: make API call to update order with PI number (maybe call out to orders store?)
      if (idx >= 0) {
        scannedTotes.value.splice(idx, 1);
      }
    }

    function getTotesBySku(
      sku: number | string,
      excludeFullyPicked = false
    ): Tote[] {
      const tasksWithSku = taskStore.state.tasks.value.filter(
        (t) => t.attributes.sku == sku
      );
      const totesWithSku = scannedTotes.value.filter((tote) =>
        tasksWithSku.some((task) => task.attributes.toteName === tote.name)
      );
      if (excludeFullyPicked) {
        return totesWithSku.filter((tote) =>
          tasksWithSku.some(
            (task) =>
              task.attributes.toteName === tote.name &&
              task.attributes.quantity !== task.attributes.quantityPicked
          )
        );
      }
      return totesWithSku;
    }

    function getTasksByToteName(
      toteName: string,
      excludePicked = false
    ): PickTask[] {
      return taskStore.state.tasks.value.filter(
        (t) =>
          (excludePicked ? t.attributes.quantityPicked > 0 : true) &&
          t.attributes.toteName === toteName
      );
    }

    return {
      ...taskStore.state,
      type,
      scannedTotes,
      getters: {
        ...taskStore.getters,
        ...quantityChallengeStore.getters,
        getTotes,
        getTotesBySku,
        getTasksByToteName,
      },
      actions: {
        ...taskStore.actions,
        ...quantityChallengeStore.actions,
        removeTasksByTote,
        removeTote,
        setPickingTasksAttributesOnAllTasks,
        setPickingTasksAttributesByTaskId,
        setPickingTasksStatusByTaskId,
        setPickingTasksQtyPickedByTaskId,
        setTotes,
        reset,
        setChallengesForAllTasks,
        setChallengeStatusByTaskAndChallengeId,
      },
    };
  },
  {
    persist: true,
  }
);
