import { api } from "@/bootstrap/api"

export const validationRequired = (confirmationValues, barcode) => {
	for (const confirmation of confirmationValues) {
		if (confirmation.toString().length === barcode.toString().length) {
			return true;
		}
	}

	return false;
}

export const isValidBarcode = (confirmationValues, barcode) => {
	if (confirmationValues.includes(barcode.toString())) {
		return true;
	}

	return false;
}

export const printGenericInsert = async (orderId, hubId) => {
	// Printing is a physical process which takes some time
	// A delay is added to ensure the user notices that an action is being executed
	const progressPromise = new Promise((resolve) => {
		setTimeout(resolve, 3000);
	});

	const printPromise = api(
		'insertPrint',
		{
			params: {
				order_id: orderId,
			},
			data: {
				hub_id: hubId,
			}
		}
	)

	const promises = await Promise.all([progressPromise, printPromise]);

	return promises[1].data.unique_key_values;
};
