<template>
  <div>
    <v-btn :to="{name: 'orders'}"
           block>
      {{ $t('orders') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'Home',
}
</script>