
import {bus} from '@/bootstrap/bus';
import Vue, {PropType} from 'vue'

type AudioElement = {
  name: string,
  audioElement: HTMLMediaElement
}

type HapticFeedbackType = {
  audio: string,
  event: string,
  vibrate: Array<number>|null
};

export default Vue.extend({
  name: 'HapticFeedback',
  data: () => ({
    audioContext: null as null | AudioContext,
    audioElements: [] as Array<AudioElement>,
  }),
  props: {
    config: {
      type: Array as PropType<HapticFeedbackType[]>,
      required: true,
    }
  },
  mounted(): void {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioContext = new AudioContext();
    for (let i = 0; i < this.uniqueAudio.length; i++) {
      const audio = this.uniqueAudio[i];
      const audioElements = this.$refs[audio] as Array<HTMLMediaElement>;
      const audioElement = audioElements[0];
      this.audioElements.push({
        name: audio,
        audioElement: audioElement,
      })
      const track = this.audioContext.createMediaElementSource(audioElement);
      track.connect(this.audioContext.destination);
    }
    this.bindEvents();
  },
  beforeDestroy(): void {
    for (let i = 0; i < this.config.length; i++) {
      bus.$off(this.config[i].event, this.notify);
    }
  },
  computed: {
    uniqueAudio(): Array<string> {
      const audio = [];
      for (let i = 0; i < this.config.length; i++) {
        if (audio.indexOf(this.config[i].audio) === -1) {
          audio.push(this.config[i].audio);
        }
      }
      return audio;
    },
  },
  methods: {
    bindEvents() {
      for (let i = 0; i < this.config.length; i++) {
        bus.$on(this.config[i].event,
            () => {
              this.notify(
                  this.config[i].audio,
                  this.config[i].vibrate
              );
            }
        );
      }
    },
    stopAllAudio(): void {
      for (let i = 0; i < this.audioElements.length; i++) {
        this.audioElements[i].audioElement.pause();
        this.audioElements[i].audioElement.currentTime = 0;
      }
    },
    notify(audio: string, vibrate: Array<number> | null): void {
      this.stopAllAudio();
      if (this.audioContext) {
        if (this.audioContext.state === 'suspended') {
          this.audioContext.resume();
        }
      }
      const audioElement = this.audioElements.find(item => item.name === audio);
      if (typeof audioElement !== 'undefined') {
        audioElement.audioElement.play();
      }
      if (vibrate) {
        window.navigator.vibrate(vibrate);
      }
    },
  },
});
