export enum TaskTypes {
  PICK = 'pick',
  PACK = 'pack',
  WAVE = 'wave',
}

export enum TaskStatus {
  NEW = 'new',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
  CANCELLED = 'cancelled',
}

export enum ChallengeTypes {
  QUANTITY = 'quantity',
}
