export enum HapticFeedbackEvents {
    EVENT_WARNING = 'haptic_event_warning',
    EVENT_SCAN = 'haptic_event_scan'
}

export function getHapticFeedbackConfig(): Array<object> {
    return [
        {
            audio: '/audio/warning.mp3',
            event: HapticFeedbackEvents.EVENT_WARNING,
            vibrate: [500, 500, 500],
        },
        {
            audio: '/audio/scan.mp3',
            event: HapticFeedbackEvents.EVENT_SCAN,
            vibrate: [500, 500, 500],
        }
    ]
}
