<template>
  <v-overlay :absolute="true" class="text-center">
    <v-progress-circular indeterminate/>
    <p>
      {{ $t('loading_next_order') }}
    </p>
  </v-overlay>
</template>

<script>
export default {
  name: 'StartNextOrder',
  async created () {
    await this.$store.dispatch('orders/startNextOrder');
  },
}
</script>
