
import { isRecountNeeded } from '@/store/cycleCounting/helpers';
import {CountEvent} from '@/types/StockCounts';
import {defineComponent} from 'vue';

export default defineComponent({
  name: "CountSummary",
  data: () => ({
    submitLoading: false as boolean,
  }),
  computed: {
    canSubmit(): boolean {
      return !!this.getStateLocation && !this.submitLoading
    },
    getStateLocation(): string | null {
      return this.$store.getters['cycleCounting/getLocation']
    },
    getCountEvents(): CountEvent [] {
      return this.$store.getters['cycleCounting/getCountEvents']
    },
    getStocks(): CountEvent [] {
      return this.$store.getters['cycleCounting/getStocks']
    },
    isEmptyLocation(): boolean {
      return this.getCountEvents.length === 0;
    },
    isEmptyStockForLocation(): boolean {
      return this.getStocks.length === 0;
    }
  },
  methods: {
    addNewCount(): void {
      this.$store.dispatch('core/redirect', {name: 'product_count'})
    },
    removeCount(index: number): void {
      // find the index of the sku and batch being removed
      this.$store.commit('cycleCounting/removeCountEvent', index)
    },
    async submitCounts(): Promise<void> {
      try {
        this.submitLoading = true;
        const now = new Date().toISOString();
        //if no events, then add empty event
        if (this.isEmptyLocation && this.isEmptyStockForLocation) {
          this.$store.commit('cycleCounting/addCountEvent', {
            sku: null,
            batch_id: null,
            batch_ref: null,
            counted_quantity: 0,
            system_quantity: 0,
            task_id: null,
            client_id: null,
            time: {start: now, end: now}
          })
        }

        // always submit count
        await this.$store.dispatch('cycleCounting/submitCount')

        if (isRecountNeeded(this.$store.state.cycleCounting)) {
          await this.$store.dispatch('cycleCounting/startRecount')
        } else {
          await this.$store.dispatch('cycleCounting/completeCount')
        }
      } catch(error) {
        console.log(error);
      } finally {
        this.submitLoading = false;
      }
    }
  },
})
