

import {bus} from "@/bootstrap/bus";
import {
  ORDER_TYPE_FILTER_PRE_PACKED,
  ORDER_TYPE_FILTER_SINGLES,
  REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE,
  REDIRECT_TO_ORDERS_ON_PICK_COMPLETE
} from '@/bootstrap/config';
import Vue from 'vue';
import VueI18n, {TranslateResult} from 'vue-i18n';
import axios from 'axios';
import {PackingType} from '@/types/PackingType';
import {OrderItem} from '@/types/OrderItem';
import {Order} from '@/types/Order';
import PackingContents from '@/components/order/PackingContents.vue';
import {Bundle} from '@/types/Bundle';

export default Vue.extend({
  name: "Packing",
  data: () => ({
    selectedPackageType: null as number|null,
    packageIdentifier: null as string|null,
    requestErrors: [] as VueI18n.TranslateResult[],
    submitLoading: false as boolean
  }),
  components: {PackingContents},
  computed: {
    order(): Order {
      return this.$store.state.picking.order;
    },
    PILabel(): TranslateResult {
      return this.packageIdentifier ? this.$t('packing_identifier_barcode') : this.$t('enter_packing_identifier_barcode');
    },
    isValidPackageIdentifier(): boolean {
      return !this.packageIdentifier || this.packageIdentifier.length === 12
    },
    packageTypes(): PackingType[] {
      return this.$store.getters['picking/mappedPackageTypes']
    },
    packageIcon(): CallableFunction {
      return (type: string): string|null => {
        const icons: {[key: string]: string} = {
          "SMLETTER": 'mdi-email',
          "LGLETTER": 'mdi-email-multiple',
          "SMPACKET": 'mdi-inbox-outline',
          "SMPARCEL": 'mdi-package',
          "MDPARCEL": 'mdi-archive',
          "LGPARCEL": 'mdi-package-variant-closed',
        }
        return icons[type] ? icons[type] : null
      }
    },
    canSubmit(): boolean {
      return !!this.packageIdentifier && this.isValidPackageIdentifier && (this.isPrePacked || !!this.selectedPackageType)
    },
    customerRequiresSpecialPackaging(): boolean {
      return this.order.specialPackagingRequiredByCustomer
    },
    itemRequiresSpecialPackaging(): boolean {
      return this.$store.getters['picking/itemRequiresSpecialPackaging']
    },
    isPrePacked(): boolean {
      return this.order.orderType === ORDER_TYPE_FILTER_PRE_PACKED
    },
    hasWarnings(): boolean {
      return this.customerRequiresSpecialPackaging ||
          this.itemRequiresSpecialPackaging
    },
    errors(): VueI18n.TranslateResult[] {
      const errors = [...this.requestErrors]
      if(!this.isValidPackageIdentifier) {
        errors.push(this.$t('barcode_length_validation'))
      }
      return errors
    },
    shouldShowPackagingDetails (): boolean {
      return !!(this.selectedPackageType === this.order.preferredContainerTypeId &&
          this.order.orderType === ORDER_TYPE_FILTER_SINGLES &&
          this.packagingId &&
          this.packagingName);
    },
    packagingId (): string|null {
      let packagingId = this.order.packagingId;
      if (packagingId) {
        packagingId = packagingId.toString()
        while (packagingId.length < 2) {
          packagingId = "0" + packagingId;
        }
      }
      return packagingId;
    },
    packagingName (): VueI18n.TranslateResult {
      return this.order.packagingType ? this.translatePackaging(this.order.packagingType) : '';
    },
    startedPickFromBarcode (): boolean {
      return this.order.startPickFromBarcode
    },
    singleOrderItems (): OrderItem[] {
      return this.order.items.filter(item => !item.in_bundle);
    },
    bundles (): Bundle[] {
      return this.order.bundles;
    },
  },
  created() {
    this.selectedPackageType = this.order.preferredContainerTypeId
    this.packageIdentifier = this.$store.getters['picking/getPackageIdentifier']
    bus.$on('barcodeScanned', this.handleBarcodeScan)
  },
  beforeDestroy() {
    bus.$off('barcodeScanned', this.handleBarcodeScan)
  },
  watch: {
    packageIdentifier() {
      this.requestErrors = []
    }
  },
  methods: {
    handleBarcodeScan(barcode: string) {
      this.packageIdentifier = barcode.trim()
    },
    translatePackaging(packagingType: string): VueI18n.TranslateResult {
      return this.$t(`package_types.${packagingType.toLowerCase()}`);
    },
    // Complete packing and redirect to the orders list
    async completePackingAndBackHome(): Promise<void> {
      this.$emit('allowOrderExit')
      return this.completePacking(REDIRECT_TO_ORDERS_ON_PICK_COMPLETE);
    },
    // Complete packing and redirect to the next order, if available
    async completePackingAndNextOrder(): Promise<void> {
      this.$emit('allowOrderExit')
      return this.completePacking(REDIRECT_TO_NEXT_ORDER_ON_PICK_COMPLETE);
    },
    async completePacking(redirectionStrategy: number): Promise<void> {
      this.requestErrors = []
      this.submitLoading = true
      try {
        const selectedPackageType = this.selectedPackageType
        const packageIdentifier = this.packageIdentifier
        await this.$store.dispatch('picking/complete', {selectedPackageType, packageIdentifier, redirectionStrategy})
      } catch (e) {
        this.submitLoading = false
        if (axios.isAxiosError(e) && e?.response?.status === 422) {
          if (e.response.data.errors?.package_identifier) {
            this.requestErrors.push(e.response.data.errors?.package_identifier[0])
          } else {
            this.requestErrors.push(e.response.data.message)
          }
        } else {
          this.$store.dispatch('core/handleError', e)
        }
      }
      this.submitLoading = false
    },
  }
})
