
import {CountTask} from "@/types/CountTasks"
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'CountTasks',
  components: {
  },
  data: () => ({
    loadingTasks: true as boolean,
    loadingTaskId: null as number | null,
  }),
  computed: {
    pickButtonColor() {
      return (task: CountTask) => {
        if (task.user_id === this.authUserId) {
          return 'success'
        }
        return null
      }
    },
    pickButtonIcon() {
      return (task: CountTask) => {
        if (task.assigned) {
          return 'mdi-alert-rhombus-outline'
        }
        return null
      }
    },
    authUserId() {
      return this.$store.getters['core/getAuthUserId']
    },
    cardColor() {
      return (task: CountTask) => {
        if (task.assigned) {
          return 'purple-light';
        }
        return null;
      }
    },
    tasks(): CountTask[] {
      return this.$store.state.cycleCounting.countTasks;
    },
    displayTaskAttributes(): (task: CountTask) => Pick<CountTask, "due_date" | "location_name"> {
      return (task: CountTask) => {
        const date = new Date(task.due_date);
        const formatted_date = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}`
        return { "due_date": formatted_date, "location_name": task.location_name };
      }
    },
    page(): number {
      return this.$store.state.cycleCounting.page
    },
    totalPages() {
      return Math.ceil(this.$store.state.cycleCounting.totalTasks / this.$store.state.cycleCounting.perPage)
    },
  },
  async created() {
    await this.retrieveTasks();
  },
  methods: {
    async retrieveTasks(): Promise<void> {
      this.loadingTasks = true;
      await this.$store.dispatch('cycleCounting/fetchStockCountTasks');
      await this.getTasks();
      this.loadingTasks = false;
    },
    async startTask(task: CountTask) {
      try {
        this.loadingTaskId = task.id;
        await this.$store.dispatch('cycleCounting/startCountTask', { ...task });
        return this.$store.dispatch('core/redirect', {name: 'product_count'})
      } finally {
        this.loadingTaskId = null;
      }
    },
    async handlePage(page: number): Promise<void> {
      this.$store.commit('cycleCounting/setPage', page)
      await this.retrieveTasks()
    },
    async getTasks(): Promise<void> {
      this.loadingTasks = true
      await this.$store.getters['cycleCounting/getTasks'];
      this.loadingTasks = false
    },
    redirectToStartManualCount(): void {
      this.$store.dispatch('cycleCounting/startManualCount')
    }
  }
})
