import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
theme: {
    themes: {
      light: {
        primary: '#5a5fa0',
        secondary: '#46d2be',
        accent: '#ffbe3c',
        success: '#46d2be',
        purple: '#a692d9',
      },
    },
  },
})
