import { api } from "@/bootstrap/api";
import {
  ApiRequestConfig,
  EndpointType,
  concurrentJsonApi,
} from "@/bootstrap/concurrentApi";
import { Attributes, JsonApiResponse, Relationships } from "@/types/JsonApi";
import { AxiosResponse } from "axios";

export const paginateJsonApiRequest = async <
  TDataIsArray extends boolean,
  TDataAttributes extends Attributes,
  TRelationships extends Relationships
>(
  endpoint: EndpointType,
  requestConfig: ApiRequestConfig,
  pageSize: number
): Promise<
  AxiosResponse<
    JsonApiResponse<TDataIsArray, TDataAttributes, TRelationships>
  >[]
> => {
  const response: AxiosResponse<
    JsonApiResponse<TDataIsArray, TDataAttributes, TRelationships>
  > = await api(endpoint, requestConfig);

  const totalCount = response.data?.total ?? pageSize;
  const hasNextPage = response.data?.links?.next;

  let numberOfPages = 1;

  if (hasNextPage) {
    numberOfPages = Math.ceil(totalCount / pageSize);
  }

  const concurrencyLimit = 4;
  const requestArray = [];
  for (
    let pageNumber = 2;
    pageNumber <= numberOfPages && hasNextPage;
    pageNumber++
  ) {
    const params = {
      ...requestConfig.params,
      "page[number]": pageNumber,
      "page[size]": pageSize,
    };
    const request = { ...requestConfig, params };
    requestArray.push(request);
  }

  const responses: AxiosResponse<
    JsonApiResponse<TDataIsArray, TDataAttributes, TRelationships>
  >[] = await concurrentJsonApi(
      'containerStock',
      requestArray,
      concurrencyLimit
    );
    responses.push(response);

    return responses;
};
