import {OrderItem} from '@/types/OrderItem'

export default class OrderItemCollection {
  protected readonly pickedItemIds: number[]
  protected readonly orderItems: OrderItem[]

  public constructor(orderItems: OrderItem[] = [], pickedItemIds: number[] = []) {
    this.pickedItemIds = pickedItemIds
    this.orderItems = orderItems
  }

  public filterPicked = (): OrderItemCollection =>
    new OrderItemCollection(
      this.toArray().filter((item) => this.pickedItemIds.some((picked) => item.id === picked)),
      this.pickedItemIds
    )

  public filterUnpicked = (): OrderItemCollection =>
    new OrderItemCollection(
      this.toArray().filter((item) => !this.pickedItemIds.some((picked) => item.id === picked)),
      this.pickedItemIds
    )

  public getFirstUnpicked = (): OrderItem | undefined =>
    this.toArray().find((item) => !this.pickedItemIds.some((picked) => item.id === picked))

  public filterByBatchId = (batchId: number): OrderItemCollection =>
    new OrderItemCollection(
      this.toArray().filter(
        (item) =>
          (item?.batches_for_location?.length &&
            item?.batches_for_location.some((batch) => batchId === batch.batch_id))
      ),
      this.pickedItemIds
    )

  public hasBatchId = (batchId: number): boolean => this.filterByBatchId(batchId).count() > 0

  public filterBatched = (): OrderItemCollection =>
    new OrderItemCollection(
      this.toArray().filter((item) =>
        item?.is_batched
      ),
      this.pickedItemIds
    )

  public hasBatched = (): boolean => this.filterBatched().count() > 0

  public filterBatchedScannedByBarcode = (): OrderItemCollection =>
    new OrderItemCollection(
      this.toArray().filter((item) =>
        item.scannedByBarcode === true && item?.is_batched
      ),
      this.pickedItemIds
    )

  public count = (): number => this.toArray().length

  public toArray = (): OrderItem[] => this.orderItems
}
