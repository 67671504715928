import { ContainerPickState } from ".";

export default {
  pickedStockIds(state: ContainerPickState): string[] | undefined {
    return state.pickedStock.map(({ id }) => id);
  },
  getContainerScanValidation(
    state: ContainerPickState
  ): ContainerPickState["containerScanValidation"] {
    return state.containerScanValidation;
  },
  isContainerScanValid(state: ContainerPickState): boolean {
    return Object.values(state.containerScanValidation).every((result) => !result.failed);
  },
}
