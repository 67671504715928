export default {
  ON_CONTAINER_SCAN: "onContainerScan",
  VALIDATE_CONTAINER_TYPE: "validateContainerType",
  VALIDATE_CONTAINER_PARENT: "validateContainerParent",
  VALIDATE_CONTAINER_STATUS: "validateContainerStatus",
  VALIDATE_CONTAINER_HAS_BATCH: "validateContainerHasBatch",
  VALIDATE_CONTAINER_ITEMS_IN_ORDER: "validateContainerItemsInOrder",
  VALIDATE_CONTAINER_STOCK: "validateContainerStock",
  VALIDATE_CONTAINER_QUANTITY: "validateContainerQuantity",
  VALIDATE_CONTAINER_QUARANTINE_STATUS: "validateContainerQuarantineStatus",
  VALIDATE_CONTAINER_BATCH_EXPIRY_MEETS_MIN_FOR_ORDER:
    "validateContainerBatchExpiryMeetsMinForOrder",
  VALIDATE_CONTAINER_BATCH_EXPIRY_IS_IN_FUTURE:
    "validateContainerBatchExpiryIsInFuture",
  VALIDATE_PICKABLE_LOCATION: "validatePickableLocation",
  VALIDATE_CONTAINER_NOT_PREVIOUSLY_SCANNED:
    "validateContainerNotPreviouslyScanned",
  ASSIGN_STOCK_TO_TASKS: "assignStockToTasks",
} as const;
