
  import Vue, { defineComponent } from 'vue'

  export default defineComponent({
    name: 'BatchQuantityInput',
    components: {},
    data: () => ({
      errorMessages: [] as string[],
      quantity:  null as number | null
    }),
    props: {
      maxQuantity: {
        required: true,
        type: [Number],
      },
      batchQuantity: {
        required: false,
        type: [Number]
      }
    },
    methods: {
      resetErrorMessages() {
        this.errorMessages = []
      },
      valueIsTruthy(value: string) {
        return !!value || value?.length === 0;
      },
      exceedsMaxQuantity() {
        return this.quantity && this.quantity > this.maxQuantity
      },
      exceedsBatchQuantity() {
        if(!this.batchQuantity) return false
        return this.quantity && this.quantity > this.batchQuantity
      },
      validateOnInput() {
        this.resetErrorMessages()
        if (this.exceedsMaxQuantity() || this.exceedsBatchQuantity()) {
          this.validate()
        }
      },
      validate() {
        if (this.exceedsMaxQuantity()) {
          this.errorMessages.push(this.$t("exceeds_remaining_task_quantity") as string);
        }
        if (this.exceedsBatchQuantity()) {
          this.errorMessages.push(this.$t("exceeds_remaining_batch_quantity") as string);
        }
        if (this.errorMessages.length) {
          return;
        }

        this.$emit('value-selected', this.quantity)
        this.resetErrorMessages()
      }
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(
            () => {
                const quantityInput = this.$refs.quantityInput as Vue
                const inputElement = quantityInput.$refs.input as HTMLInputElement
                inputElement.focus()
            }
        )
      })
    }
  })
  