import i18n from '@/plugins/i18n'
import {LocaleMessage} from "vue-i18n";

export const SORT_ASC = 'ASC'
export const SORT_DESC = 'DESC'
export const SORT_PRODUCT = 'sort_sku_id'
export const SORT_CUSTOMER = 'sort_huboo_box'
export const SORT_ORDER_TYPE = 'sort_work_group'
export const SORT_LOCATION = 'sort_location'
export const SORT_DATE = 'sort_date'
export const SORT_COURIER_TYPE = 'sort_courier_type'

export type SortOptionType = {
    name: string | LocaleMessage,
    type: null | string,
    value: null | string,
    id: null | string
}

export function getSorts (): Array<SortOptionType> {
    return [
        {
            name: i18n.t('default_order'),
            type: null,
            value: null,
            id: null
        },
        {
            name: i18n.t('by_courier_type'),
            type: SORT_COURIER_TYPE,
            value: SORT_DESC,
            id: SORT_COURIER_TYPE
        },
        {
            name: i18n.t('by_product') + ' (' + i18n.t(SORT_DESC) + ')',
            type: SORT_PRODUCT,
            value: SORT_DESC,
            id: SORT_PRODUCT + SORT_DESC
        },
        {
            name: i18n.t('by_product') + ' (' + i18n.t(SORT_ASC) + ')',
            type: SORT_PRODUCT,
            value: SORT_ASC,
            id: SORT_PRODUCT + SORT_ASC
        },
        {
            name: i18n.t('by_customer') + ' (' + i18n.t(SORT_DESC) + ')',
            type: SORT_CUSTOMER,
            value: SORT_DESC,
            id: SORT_CUSTOMER + SORT_DESC
        },
        {
            name: i18n.t('by_customer') + ' (' + i18n.t(SORT_ASC) + ')',
            type: SORT_CUSTOMER,
            value: SORT_ASC,
            id: SORT_CUSTOMER + SORT_ASC
        },
        {
            name: i18n.t('by_order_type') + ' (' + i18n.t(SORT_DESC) + ')',
            type: SORT_ORDER_TYPE,
            value: SORT_DESC,
            id: SORT_ORDER_TYPE + SORT_DESC
        },
        {
            name: i18n.t('by_order_type') + ' (' + i18n.t(SORT_ASC) + ')',
            type: SORT_ORDER_TYPE,
            value: SORT_ASC,
            id: SORT_ORDER_TYPE + SORT_ASC
        },
        {
            name: i18n.t('by_location') + ' (' + i18n.t(SORT_DESC) + ')',
            type: SORT_LOCATION,
            value: SORT_DESC,
            id: SORT_LOCATION + SORT_DESC
        },
        {
            name: i18n.t('by_location') + ' (' + i18n.t(SORT_ASC) + ')',
            type: SORT_LOCATION,
            value: SORT_ASC,
            id: SORT_LOCATION + SORT_ASC
        },
        {
            name: i18n.t('oldest_first'),
            type: SORT_DATE,
            value: SORT_ASC,
            id: SORT_DATE + SORT_ASC
        },
        {
            name: i18n.t('newest_first'),
            type: SORT_DATE,
            value: SORT_DESC,
            id: SORT_DATE + SORT_DESC
        },
    ]
}
