import {CountEvent} from "@/types/StockCounts";
import {StockForLocation} from "@/types/Stock";
import {CycleCountingStateInterface} from ".";
import { CountTasks } from '@/types/CountTasks';

export default {
  getLocation(state: CycleCountingStateInterface): string | null {
    return state.location_name;
  },
  getCountEvents(state: CycleCountingStateInterface): CountEvent[] {
    return state.countEvents;
  },
  getStocks(state: CycleCountingStateInterface): StockForLocation[] {
    return state.stocks;
  },
  getTasks(state: CycleCountingStateInterface): CountTasks {
    return state.countTasks;
  },
  getActiveTaskId(state: CycleCountingStateInterface): number | null {
    return state.activeTaskId;
  }
}
