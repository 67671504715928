import {LocaleMessage} from "vue-i18n";

export enum AbandonmentReasonsEnum {
    OUT_OF_STOCK = 'out_of_stock',
    DAMAGED_STOCK = 'damaged_stock',
    REPLENISHMENT_REQUIRED = 'replenishment_required',
}

export type AbandonmentReasonsI18NMapType = {
    id: AbandonmentReasonsEnum,
    label: LocaleMessage
}

export const AbandonmentReasonsI18nMap: Array<AbandonmentReasonsI18NMapType> = [
    {
        id: AbandonmentReasonsEnum.OUT_OF_STOCK,
        label: 'out_of_stock'
    },
    {
        id: AbandonmentReasonsEnum.DAMAGED_STOCK,
        label: 'damaged_stock'
    },
    {
        id: AbandonmentReasonsEnum.REPLENISHMENT_REQUIRED,
        label: 'replenishment_required'
    },
]

export type AbandonPayload = {
    abandonment_reason?: AbandonmentReasonsEnum
    order_id?: number,
    huboo_sku: number | null,
}
