import {
    ORDER_STEP_ID_INSERT,
    ORDER_STEP_ID_PICK,
    ORDER_STEP_ID_SPLITPACK,
    ORDER_TYPE_FILTER_PRE_PACKED,
    ORDER_STEP_ID_DISPATCH_NOTE,
} from "@/bootstrap/config"

export const isDispatchStepRequired = (getters) => {
    return (
        (
               getters['getState'] === ORDER_STEP_ID_PICK
            || getters['getState'] === ORDER_STEP_ID_DISPATCH_NOTE
        )
        && getters['requiresDispatch']
        && !getters['hasInsert']
    )
}

export const isPickingStepRequired = (getters) => {
    return (
           getters['getState'] === null
        || getters['getState'] === ORDER_STEP_ID_PICK
        || getters['getState'] === ORDER_STEP_ID_SPLITPACK
    )
    && getters['unpickedItems'].length > 0
}

export const isPackingSlipStepRequired = (getters) => {
    return (
        (
               getters['getState'] === ORDER_STEP_ID_PICK
            || getters['getState'] === ORDER_STEP_ID_DISPATCH_NOTE
        )
        && getters['requiresInsert']
        && !getters['hasInsert']
    )
    && getters['getOrderType'] !== ORDER_TYPE_FILTER_PRE_PACKED
}

export const isPackingStepRequired = (getters) => {
    return (
        getters['getOrderType'] !== ORDER_TYPE_FILTER_PRE_PACKED
        && (
               getters['getState'] === ORDER_STEP_ID_PICK
            || getters['getState'] === ORDER_STEP_ID_DISPATCH_NOTE
            || getters['getState'] === ORDER_STEP_ID_INSERT
        )
        &&
        (
              !getters['requiresInsert']
            || getters['hasInsert']
        )
    ) ||
    (
           getters['getOrderType'] === ORDER_TYPE_FILTER_PRE_PACKED
        && getters['getState'] === ORDER_STEP_ID_PICK
    )
}
