import {CountEvent, GroupedCounts} from "@/types/StockCounts";
import {StockForLocation} from "@/types/Stock";

export const groupCountsBySkuAndBatch = (countEvents: CountEvent[], stocks: StockForLocation[], activeTaskId: number | null): GroupedCounts => {
  let groupedCounts: GroupedCounts = {};

  groupedCounts = countEvents.reduce((prev: GroupedCounts, countEvent: CountEvent) => {
    const countKey = (countEvent.sku ?? 0) + (countEvent.batch_id ? '-' + countEvent.batch_id : '');
    if(prev[countKey]) {
      prev[countKey].counted_quantity += countEvent.counted_quantity;
      prev[countKey].time.end = countEvent.time.end;
    } else {
      prev[countKey] = {
        sku: countEvent.sku,
        batch_id: countEvent.batch_id,
        batch_ref: countEvent.batch_ref,
        system_quantity: 0,
        counted_quantity: countEvent.counted_quantity,
        time: countEvent.time,
        task_id: countEvent.task_id ?? activeTaskId,
        client_id: countEvent.client_id
      }
    }
    return prev;
  }, groupedCounts);

  groupedCounts = stocks.reduce((prev: GroupedCounts, stock: StockForLocation) => {
    const stockKey = stock.attributes.sku + (
      stock.attributes.batch?.id ? '-' + stock.attributes.batch?.id : ''
    );
    if(prev[stockKey]) {
      prev[stockKey].system_quantity += stock.attributes.quantity;
      prev[stockKey].client_id = stock.attributes.client_id;
    } else {
      prev[stockKey] = {
        sku: stock.attributes.sku,
        batch_id: stock.attributes.batch?.id ?? null,
        batch_ref: stock.attributes.batch?.reference ?? null,
        counted_quantity: 0,
        system_quantity: stock.attributes.quantity,
        task_id: activeTaskId,
        client_id: stock.attributes.client_id,
        time: {
          start: new Date().toISOString(),
          end: new Date().toISOString()
        }
      }
    }
    return prev;
  }, groupedCounts);

  return groupedCounts;
}
