
import Vue from "vue";
import {
  AbandonmentReasonsEnum,
  AbandonmentReasonsI18nMap,
  AbandonPayload,
} from "@/utilities/AbandonmentReasons";
import ordersApiGateway from "@/api/ordersApiGateway";
import auditLog from "@/bootstrap/auditLog";
import SkuAbandonModal from "@/components/order/SkuAbandonModal.vue";

export default Vue.extend({
  name: "AbandonDialog",
  components: {SkuAbandonModal},
  data: () => ({
    loading: false,
    abandonmentReasons: AbandonmentReasonsI18nMap,
    confirmModal: false,
    reasonClicked: null as AbandonmentReasonsEnum | null,
    openSkuSelectionModal: false as boolean,
  }),
  props: {
    wavePick: {
      type: Boolean,
      default: false,
    },
    hubooSku: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    this.reasonClicked = null;
  },
  computed: {
    goBackButtonText() {
      return this.wavePick
        ? this.$i18n.t("abandon_order.confirm_dialog.button.go_back_to_list")
        : this.$i18n.t("stop_picking");
    },
    orderItems() {
      return this.$store.getters['picking/getOrderItems']
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    showConfirmModal(reason: AbandonmentReasonsEnum | null) {
      if (this.wavePick && !reason) {
        this.$emit("allowOrderExit");
        this.$emit("backToListClicked");
        return;
      }
      this.reasonClicked = reason;
      this.confirmModal = true;
    },
    showSkuModal(reason: AbandonmentReasonsEnum | null) {
      if (this.orderItems.length === 1) {
        this.abandon(reason, +this.orderItems[0].sku);
        return;
      }
      this.reasonClicked = reason;
      this.openSkuSelectionModal = true
    },
    handleSkuSelection(hubooSku: number): void {
      this.openSkuSelectionModal = false;
      this.abandon(this.reasonClicked, hubooSku);
    },
    confirmAbandon(): void {
      this.$emit("allowOrderExit");
      this.$store.dispatch("core/redirect", {
        name: "wave_pick_abandon",
        params: {
          sku: this.hubooSku,
          reason: this.reasonClicked,
        },
      });
      this.reasonClicked = null;
    },
    async abandon(reason: AbandonmentReasonsEnum | null, sku: number | null): Promise<void> {
      this.loading = true;
      try {
        this.$emit("allowOrderExit");
        const payload: AbandonPayload = {
          order_id: this.$store.getters['picking/getOrderId'],
          huboo_sku: sku
        }
        if (reason) {
          payload.abandonment_reason = reason
        }
        await ordersApiGateway.abandonOrder(payload)
        auditLog.logAbandonOrder(reason?.toString() ?? 'stop_picking', sku)
        await this.$store.dispatch('core/redirectToOrders', {}, {root: true})
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
});
