import internalContainerPickActions from "./containerPick/actionTypes";
import internalContainerPickMutations from "./containerPick/mutationTypes";

export { ContainerPickState } from "./containerPick";

const prefixModuleName = <T extends Record<string, string>>(
  moduleName: string,
  types: T
) => {
  return Object.entries(types).reduce((prev, [key, value]) => {
    prev[key] = moduleName + "/" + value;
    return prev;
  }, {} as Record<string, string>) as T;
};

const containerPickModuleName = "containerPick";

export const containerPickActions = prefixModuleName(
  containerPickModuleName,
  internalContainerPickActions
);

export const containerPickMutations = prefixModuleName(
  containerPickModuleName,
  internalContainerPickMutations
);
