import { computed } from "vue";
import { ChallengeTypes } from '../tasks/types';
import { useChallenge } from './base-challenge';

export type QuantityChallengeAttributes = {
  quantityRequired: number | null,
  quantityEntered: number | null,
}

export const useQuantityChallenge = () => {

  const challenge = useChallenge<QuantityChallengeAttributes>();

  function reset() {
    challenge.state.value = {
      id: 0,
      type: ChallengeTypes.QUANTITY,
      passed: false,
      attributes: {
        quantityRequired: null,
        quantityEntered: null,
      },
      errorMessages: [],
    };
  }

  const getters = {
   getChallenge : computed(() => challenge.state.value),
  }

  const actions = {
    ...challenge.actions,
    reset,
  }

  return {
    state: {
      challenge,
    },
    actions,
    getters
  }
}
