<template>
  <v-dialog v-model="shouldDisplay"
            max-width="500"
            persistent
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('instructions') }}
      </v-card-title>
      <v-card-text>
        <div>
          <p>
            {{ $t('scan_qvc_dispatch_barcode') }}:
          </p>
        </div>
        <img :src="'/images/QvcInstructions.png'" alt="Qvc instructions image" width="100%">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            id="close_btn"
            type="button"
            color="secondary"
            @click="close"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InstructionsModal',
  data: () => ({
    shouldDisplay: true
  }),
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
