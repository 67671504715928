import {api, fetchAccessToken} from '@/bootstrap/api'
import router from '@/bootstrap/router'
import moment from 'moment'
import setState from '@/utilities/store'
import {NOTIFICATIONS_DEFAULT_TIMEOUT, SESSION_TIMEOUT} from "@/bootstrap/config"
import auditLog from "@/bootstrap/auditLog";
import i18n from '@/plugins/i18n';
import NotificationTypes from "@/enums/NotificationTypes";
import {datadogLogs} from "@datadog/browser-logs";

const defaultState = () => ({
    accessToken: null,
    accessTokenExpiresAt: null,
    hubId: null,
    authUser: null,
    notifications: [],
    title: null,
    activeSessionTime: null,
    redirectToStartPickWithBarcode: false,
})


export default {
    state: defaultState(),
    getters: {
        getTitle (state) {
            return state.title ? state.title : i18n.t('perfect_picking')
        },
        getAuthUser(state) {
            return state.authUser ?? null
        },
        getAuthUserId (state) {
            return state.authUser ? state.authUser.id : null
        },
        isAuthenticated (state) {
            return !!state.accessToken && !!state.authUser
        },
        getHubId (state) {
            return state.hubId
        },
        userHasRole (state) {
            return (roleName) => {
                return state.authUser.roles.some(role => role.name === roleName)
            }
        },
        userHasPermission (state) {
            return (permissionName) => {
                // Do any of the user's roles have the given permission?
                return state.authUser.roles.some(
                    role => role.permissions.some(
                        permission => permission.name === permissionName
                    )
                )
            }
        },
    },
    mutations: {
        setNotifications (state, notifications) {
            state.notifications = notifications
        },
        addNotification (state, notification) {
            state.notifications.push(notification)
        },
        removeNotification (state, notification) {
            const index = state.notifications.indexOf(notification);
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        },
        setAccessToken (state, token) {
            state.accessToken = token
            state.accessTokenExpiresAt = moment().add(token.expires_in, 'seconds')
        },
        setAuthUser (state, authUser) {
            state.authUser = authUser
        },
        setHubId (state, hubId) {
            state.hubId = hubId
        },
        setTitle (state, title) {
            state.title = title
        },
        resetState (state) {
            setState(state, defaultState())
        },
        setActiveSessionTime (state) {
            state.activeSessionTime = moment()
        },
        setRedirectToStartPickWithBarcode (state, redirect) {
            state.redirectToStartPickWithBarcode = redirect
        },
    },
    actions: {
        handleHubSelect ({commit}, hubId) {
            commit('setHubId', hubId)
            commit('orders/resetState', null, {root: true})
        },
        redirect (_, routeInfo) {
            const routeNamesDiffer = router.currentRoute.name !== routeInfo?.name
            const routeParamsDiffer = JSON.stringify(router.currentRoute?.params) !== JSON.stringify(routeInfo?.params)
            if (routeNamesDiffer || routeParamsDiffer) {
                router.push(routeInfo)
            }
        },
        async redirectToOrders ({dispatch, state}) {
            if (state.redirectToStartPickWithBarcode) {
                const hubId = state.hubId
                dispatch('redirect',
                    {name: 'start-pick-with-barcode', params: {hub_id: hubId}}
                )
            } else {
                dispatch('redirect', {name: 'orders'})
            }
        },
        handleError ({dispatch}, error) {
            const extractMessageFromResponseDataError = (response) => {
                const messageKeys = [
                    'message',
                    'statusText',
                    'error',
                    'data',
                ]
                if (typeof response !== 'undefined') {
                    for (const index in messageKeys) {
                        if (typeof response[messageKeys[index]] !== 'undefined') {
                            return response[messageKeys[index]]
                        }
                    }
                }
                return i18n.t('oops_something_went_wrong');
            };
            if (
                typeof error.response !== 'undefined' &&
                (error.response.status === 401 || error.response.status === 419) &&
                router.currentRoute.name !== 'logout'
            ) {
                dispatch('redirect', {name: 'logout'})
            }
            let message = error.message
            if (typeof error.response !== 'undefined') {
                const response = error.response
                if (response.status) {
                    message = response.status + ' - '
                }
                if (parseInt(response.status) === 422) {
                    if (typeof error.response.data?.errors === 'object') {
                        message += Object.values(error.response.data.errors).flat().join('\n')
                    }
                    if (typeof error.response.data?.validationErrors === 'object') {
                        message += Object.values(error.response.data.validationErrors).map(message => message.msg).join('\n')
                    }
                } else {
                    message += extractMessageFromResponseDataError(response.data)
                }
            }
            // NOTE: quick fix to rectify urgent issue AB#26790 during peak. A more thorough fix will be applied as part of AB#25749.
            if (error.response?.data?.exception === 'OrderStartedByAnotherUserException') {
                dispatch('addNotification', {message: message, type: NotificationTypes.NOTIFICATION_TYPE_INFO, timeout: 10000});
            } else {
                dispatch('addNotification', {message: message, type: NotificationTypes.NOTIFICATION_TYPE_ERROR});
            }
        },
        addNotification ({commit}, notification) {
            commit('addNotification', notification);
            let timeout = NOTIFICATIONS_DEFAULT_TIMEOUT;
            if(typeof notification.timeout !== 'undefined' && notification.timeout !== null){
                timeout = notification.timeout;
            }
            if (timeout) {
                setTimeout(function () {
                    commit('removeNotification', notification)
                }, timeout)
            }
        },
        checkActiveSession ({state, dispatch}) {
            if (
                state.activeSessionTime &&
                moment(state.activeSessionTime).add(SESSION_TIMEOUT, 'milliseconds') < moment() &&
                router.currentRoute.name !== 'logout'
            ) {
                dispatch('redirect', {name: 'logout'})
            }
        },
        async fetchAccessToken ({commit}, {email, password}) {
            const token = await fetchAccessToken(email, password)
            if (token) {
                commit('setAccessToken', token)
            }
            return token
        },
        async logout ({dispatch, commit, getters}) {
            try {
                await auditLog.commit()
            } catch (e) {
                // Prevent application interruption in case audit logs fail to commit due to invalid tokens
                datadogLogs.logger.error(e.message, e)
            }
            if (getters.isAuthenticated) {
                try {
                    api('logout', {handleError: false})
                } catch (e) {
                    // Prevent application interruption in case logout on backend is not available
                    datadogLogs.logger.error(e.message, e)
                }
                commit('resetState')
                commit('orders/resetState', null, {root: true})
            }
            dispatch('redirect', {name: 'login'})
        },
        async getAuthUser ({commit}) {
            const results = await api('authUser', {handleError: false})
            const authUser = results.data
            commit('setAuthUser', authUser)
        },
    },
    namespaced: true
}
