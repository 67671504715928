<template>
  <v-dialog v-model="shouldDisplay"
            max-width="500"
            persistent
  >
    <v-card>
      <v-form>
        <v-card-title class="headline">
          {{ $t('start_pick_with_barcode_is_enabled') }}
        </v-card-title>
        <v-card-text>
          {{ $t('client_has_start_pick_with_barcode_enabled') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
              id="close_start_pick_modal_btn"
              type="button"
              color="primary"
              @click="close"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StartPickModal",
  data: () => ({
    shouldDisplay: true
  }),
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
