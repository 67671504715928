<template>
  <v-dialog
      :value="quantityRequired"
      @input="$emit('close')"
      max-width="500"
  >
    <v-card>
      <v-form @submit.prevent="validate">
        <v-card-title class="headline">
          {{ $t('confirm_quantity') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
              id="item_quantity_modal_quantity_input"
              :label="$t('quantity')"
              v-model="quantity"
              type="number"
              :rules="[v => !!v]"
              :error-messages="errorMessages"
              ref="quantityInput"
              @input="validateOnInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              id="item_quantity_modal_close_btn"
              color="accent"
              @click="$emit('close')"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
              id="item_quantity_modal_submit_btn"
              :disabled="!quantity"
              type="submit"
              color="secondary"
              @click="validate"
          >
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'QuantityChallenge',
  components: {},
  data: () => ({
    errorMessages: [],
    quantity: null,
  }),
  props: {
    quantityRequired: {
      required: true,
      type: [Number, Boolean],
    }
  },
  methods: {
    resetErrorMessages() {
      this.errorMessages = []
    },
    validateOnInput() {
      if (parseInt(this.quantity) >= this.quantityRequired) {
        this.validate()
        return
      }
      this.resetErrorMessages()
    },
    validate() {
      if (parseInt(this.quantity) !== this.quantityRequired) {
        this.errorMessages = [this.$t('incorrect_quantity')]
        return
      }
      this.$emit('pass')
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(
          () => {
            this.$refs.quantityInput.$refs.input.focus()
          }
      )
    })
  }
}
</script>