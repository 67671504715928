<template>
  <v-dialog v-model="shouldDisplay"
            max-width="500"
            persistent
  >
    <v-card>
      <v-form>
        <v-card-title class="headline">
          {{ $t('item_already_picked_and_packed') }}
          <div>
            <span>{{ $t('order_number') + ' ' }} </span>
            <span class="font-weight-bold">{{ this.barcodeNumber }} </span>
          </div>
        </v-card-title>
        <v-card-text>
          {{ $t('please_scan_another_order') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
              id="close_already_picked_and_packed_btn"
              type="button"
              color="primary"
              @click="this.close"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlreadyPickedModal',
  data: () => ({
    shouldDisplay: true
  }),
  props: {
    barcodeNumber: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  }
}
</script>
