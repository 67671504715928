
import { defineComponent, PropType } from "vue";
import { OrderItem } from "@/types/OrderItem";
import { bus } from "@/bootstrap/bus";
import { HapticFeedbackEvents } from "@/bootstrap/HapticFeedbackConfig";

export default defineComponent({
  name: "BatchProductBarcodeVerification",
  components: {},
  data: () => ({
		barcodesScanned: 0,
    currentOrderItem: {} as OrderItem,
		productImageSource: '',
    scanResultError: false
  }),
  props: {
    batchQuantity: {
      required: false,
      type: Number
    },
    batchReference: {
      required: true,
      type: String
    },
    maxQuantity: {
      required: true,
      type: Number
    },
    orderItem: {
      required: true,
      type: Object as PropType<OrderItem>,
    },
    scanResult: {
      required: true,
      type: String
    },
    scanResultCount: {
      default: 0,
      required: true,
      type: Number
    },
  },
  methods: {
    submit() {
      this.$emit('submit', this.barcodesScanned);
    }
  },
  computed: {
    disableSubmit () {
      return this.scanResultError || this.barcodesScanned === 0
    }
  },
  created() {
    this.currentOrderItem = this.orderItem;
    this.productImageSource = this.orderItem.image?.trim() ? this.orderItem.image : '/images/ProductPlaceholderImage.jpg';
  },
  watch: {
    scanResultCount: function () {
      if (this.scanResultError) {
        return;
      }

      if (this.currentOrderItem.barcodes.findIndex(bc => bc === this.scanResult) > -1) {
        ++this.barcodesScanned;
        bus.$emit(HapticFeedbackEvents.EVENT_SCAN);
      } else {
        this.scanResultError = true;
        bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
      }

      if (
        this.maxQuantity === this.barcodesScanned || 
        this.batchQuantity === this.barcodesScanned
      ) {
        this.submit();
      }
    }
  }
});
