import {PickTask} from "@/stores/tasks/pick-tasks";
import {ToteLabel} from "@/types/WavePicking";

export const getUniquePickTasksBasedOnSku = (nonUniqueTasks: PickTask[]): PickTask[] => {
  return nonUniqueTasks.reduce((prev: PickTask[], currentTask: PickTask) => {
    const index = prev.findIndex((prevTask: PickTask) => prevTask.attributes.sku === currentTask.attributes.sku);
    if (index > -1) {
      prev[index].attributes.quantity += currentTask.attributes.quantity;
      prev[index].attributes.quantityPicked += currentTask.attributes.quantityPicked;
    } else {
      prev.push({...currentTask});
    }
    return prev;
  }, []);
}

export const compareLocation = (a: PickTask, b: PickTask): number => {
  const aPicked = isTaskPicked(a);
  const bPicked = isTaskPicked(b);
  if (aPicked === bPicked && a.attributes.location) {
    return a.attributes.location.localeCompare(b.attributes.location);
  }
  return aPicked > bPicked ? 1 : -1;
}

export const isTaskPicked = (task: PickTask) => {
  return task.attributes.quantity === task.attributes.quantityPicked;
}

export const decodeToteScan = (scanResult: string): ToteLabel|undefined => {
  try {
    const content = JSON.parse(scanResult);
    if (content.id && typeof content.id === 'number' && content.identifier && typeof content.identifier === 'string') {
      return content as ToteLabel
    }
  } catch (_e) {
    // this space intentionally left blank
  }
}
