<template>
  <div class="flex justify-space-between align-center">
    <v-overlay :value="loading" :opacity="0.33" :z-index="9999">
      <v-progress-circular indeterminate :size="100" color="primary"></v-progress-circular>
    </v-overlay>
    <confirmation-modal v-if="failedToFindOrder"
                        @confirm="handleConfirmation"
                        @close="handleClose"
                        :barcode-number="barcodeNumber"
    />
    <already-picked-modal v-if="orderAlreadyPicked"
                          :barcode-number="barcodeNumber"
                          @close="handleClose"
    />
    <instructions-modal v-if="showInstructionsModal" @close="handleClose"/>
    <v-card>
      <v-form @submit.prevent="submit">
        <v-card-title class="headline">
          {{ $t('scan_or_enter_barcode_number') }}
        </v-card-title>
        <v-card-text>
          <v-text-field
              id="start_pick_from_barcode_input"
              :label="$t('scan_or_enter_barcode_number')"
              v-model="barcodeNumber"
              type="string"
              :rules="[v => !!v]"
              ref="barcodeInput"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
              id="start_pick_from_barcode_instruction_btn"
              type="button"
              color="info"
              @click="openInstructionModal"
          >
            {{ $t('instructions') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              id="barcode_number_confirmation_submit_btn"
              type="submit"
              color="secondary"
          >
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {bus} from "@/bootstrap/bus"
import {api} from "@/bootstrap/api"
import router from "@/bootstrap/router"
import auditLog from "@/bootstrap/auditLog"
import ConfirmationModal from '@/components/start-pick-from-barcode/ConfirmationModal'
import AlreadyPickedModal from '@/components/start-pick-from-barcode/AlreadyPickedModal'
import InstructionsModal from '@/components/start-pick-from-barcode/InstructionsModal'

export default {
  name: 'StartPickFromBarcode',
  components: {ConfirmationModal, AlreadyPickedModal, InstructionsModal},
  data:
      () => ({
        barcodeNumber: null,
        failedToFindOrder: false,
        loading: false,
        orderAlreadyPicked: false,
        showInstructionsModal: false,
      }),
  async created() {
    bus.$on('barcodeScanned', await this.handleBarcodeScan)
  },
  beforeDestroy() {
    bus.$off('barcodeScanned', this.handleBarcodeScan)
    this.commitAuditLog()
  },
  methods: {
    openInstructionModal() {
      this.showInstructionsModal = true
    },
    async handleConfirmation() {
      this.loading = true
      try {
        await api('handleUnfoundRemoteOrderId', {
          params: {
            remote_order_id: this.barcodeNumber
          },
          data: {
            hub_id: this.$store.state.core.hubId,
            user_id: this.$store.state.core.authUser.id
          }
        })
      } catch (error) {
        this.loading = false
      }
      auditLog.logFailedToFindOrder(this.barcodeNumber)
      this.barcodeNumber = null
      this.failedToFindOrder = false
      this.loading = false
    },
    handleClose() {
      this.barcodeNumber = null
      this.failedToFindOrder = false
      this.orderAlreadyPicked = false
      this.showInstructionsModal = false
    },
    startOrder(orderId) {
      router.push({name: 'order', params: {orderId: orderId}})
    },
    async submit() {
      this.loading = true
      if (!this.barcodeNumber) {
        this.loading = false
        return null
      }
      const order = await this.findOrderByRemoteId(this.barcodeNumber)
      if (order) {
        this.startOrder(order.id)
      }
    },
    async findOrderByRemoteId(remoteOrderId) {
      if (!remoteOrderId) {
        return null
      }
      try {
        const results = await api('remoteOrderId', {
          params: {
            remote_order_id: remoteOrderId,
            hub_id: this.$store.state.core.hubId,
          },
          handleError: false,
        })
        this.loading = false
        return results.data
      } catch (e) {
        this.loading = false
        if (e.response.status === 404) {
          this.failedToFindOrder = true
        } else if (e.response.status === 400) {
          this.orderAlreadyPicked = true
        } else {
          await this.$store.dispatch('core/handleError', e)
        }
      }
    },
    async handleBarcodeScan(barcode) {
      this.barcodeNumber = barcode
      await this.submit()
    },
    commitAuditLog() {
      auditLog.commit()
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.barcodeInput.$refs.input.focus()
      })
    })
  }
}
</script>
