const DEFAULT_CONCURRENCY = 2;

export const createPromiseManager = <T>() => {
  const promiseStore: Record<number, Promise<void>> = {};
  let concurrency = 0;
  let promises: Promise<T>[] = [];
  let concurrencyLimit = DEFAULT_CONCURRENCY;

  const removePromise = (page: number): void => {
    delete promiseStore[page];
  };
  const addPromise = (
    index: number,
    promise: Promise<T>,
    resolvingFunction: (data: T) => Promise<void>
  ): void => {
    concurrency++;
    promiseStore[index] = promise.then((data) => {
      removePromise(index);
      concurrency--;
      resolvingFunction(data);
    });
  };

  const getConcurrency = (): number => concurrencyLimit;

  const getPromises = (): Promise<void>[] => Object.values(promiseStore);

  const getPromiseCount = (): number => getPromises().length;

  const setPromises = (promisesToAdd: Promise<T>[]) => {
    promises = promisesToAdd;
    return promiseManager;
  }

  const setConcurrency = (concurrency: number) => {
    concurrencyLimit = Math.max(concurrency, DEFAULT_CONCURRENCY);
    return promiseManager;
  }

  const resolve = async () => {
    const responses: T[] = [];
    let index = 1;
    for (const promise of promises) {
      addPromise(index, promise, async (data: T) => {
        responses.push(data);
      });
  
      if (concurrency >= concurrencyLimit) {
        await Promise.race(getPromises());
      }
      index++;
    }
  
    await Promise.all(getPromises());

    return responses;
  }

  const promiseManager =  {
    getPromises,
    getPromiseCount,
    getConcurrency,
    setPromises,
    setConcurrency,
    resolve
  };

  return promiseManager;
};
