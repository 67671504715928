import {ActionContext} from "vuex";
import {StateInterface} from "@/bootstrap/store";
import {ulid} from "ulid";
import {StockCountRequest, updateTaskStatusRequest} from "@/types/StockCounts";
import {api} from "@/bootstrap/api";
import axios, {AxiosError, AxiosPromise, AxiosResponse} from "axios";
import {CycleCountingStateInterface} from "@/store/cycleCounting";
import {groupCountsBySkuAndBatch} from '@/utilities/groupCounts';
import NotificationTypes from '@/enums/NotificationTypes';
import i18n from '@/plugins/i18n';
import {CycleCountStatus} from '@/enums/CycleCountTaskStatus';
import {ApiCountTask, CountTask, CountTasks} from '@/types/CountTasks';
import moment from "moment";
import {JsonApiResponseWithManyData} from "@/types/JsonApi";
import {ProductAttributes, StockForLocation} from "@/types/Stock";
import {getMarginForActiveTask} from "@/store/cycleCounting/helpers";

export default {
  async submitCount({state, dispatch, rootGetters}: ActionContext<CycleCountingStateInterface, StateInterface>): Promise<boolean> {

    const hubNumber = rootGetters["core/getHubId"];
    const countIdentifier = ulid();

    const allCountEvents = groupCountsBySkuAndBatch(state.countEvents, state.stocks, state.activeTaskId);

    const stockCountRequestData: StockCountRequest[] = Object.entries(allCountEvents)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_countKey, countEvent]) => ({
        type: "stock-count",
        attributes: {
          count_identifier: countIdentifier,
          // site_id will probably be deprecated in the next iteration
          site_id: 0,
          hub_number: hubNumber,
          location_name: state.location_name ?? 'missing location',
          huboo_sku: countEvent.sku,
          batch_id: countEvent.batch_id,
          discrepancy_margin: getMarginForActiveTask(state),
          counted_quantity: countEvent.counted_quantity,
          system_quantity: countEvent.system_quantity,
          task_id: countEvent.task_id ? Number(countEvent.task_id) : null,
          client_id: countEvent.client_id ?? null,
          time: {
            start: countEvent.time.start,
            end: countEvent.time.end
          }
        }
      })
    );

    try {
      await api('storeStockCounts', {data: {data: stockCountRequestData}})
      return true
    } catch(err) {
      console.error(err)
      await dispatch('core/handleError', err, {root: true});
      return false
    }
  },

  async checkLocationName({dispatch, rootGetters}: ActionContext<CycleCountingStateInterface, StateInterface>, name: string): Promise<boolean|null> {
    try {
      await api(
        'getHubLocations',
        {
          inlineParams: {
            hubNumber: rootGetters['core/getHubId'],
            locationName: name
          },
          handleError: false
        }
      )
      return true
    } catch (err: unknown) {
      if (axios.isAxiosError(err) && (<AxiosError>err).response?.status === 404) {
        return false
      }
      await dispatch('core/handleError', err, {root: true})
      return null
    }
  },

  async fetchStockForLocation({commit, dispatch, rootGetters}: ActionContext<CycleCountingStateInterface, StateInterface>, payload: { name: string; hubooSku: number|null; }): Promise<AxiosPromise|null> {
    try {
      commit('clearStocks');
      commit('clearCountEvents');
      commit('setIsRecount', false);

      return await api(
        'getStockForLocation',
        {
          headers: {
            Accept: 'application/vnd.api+json'
          },
          params: {
            'filter[hub_number]': rootGetters['core/getHubId'],
            'filter[location_name]': payload.name,
            'filter[huboo_sku]': payload.hubooSku,
            'page[size]': 1000
          },
          handleError: false
        }
      )
    } catch (err: unknown) {
      if (axios.isAxiosError(err) && (<AxiosError>err).response?.status === 404) {
        return null
      }
      await dispatch('core/handleError', err, {root: true})
      return null
    }
  },

  async fetchStockCountTasks({dispatch, commit, rootGetters, state}: ActionContext<CycleCountingStateInterface, StateInterface>): Promise<boolean> {
    // might need to clear out any old tasks (in case of an API error?)
    try {

      const {data} = await api(
        'getStockCountTasks',
        {
          headers: {
            Accept: 'application/vnd.api+json'
          },
          params: {
            'filter[stockCountRequest.hub_number][eq]': rootGetters['core/getHubId'],
            'filter[status][neq]': CycleCountStatus.COMPLETE,
            'filter[due_date][lte]': moment().format('YYYY-MM-DD').toString(),
            'page[size]': state.perPage ?? 10,
            'page[number]': state.page ?? 1,
            'sort': 'due_date'
          },
          handleError: false
        }
      )

      commit('setTotalTasks', data.meta.total ?? null);

      if(data.data.length > 0) {
        const countTasks: CountTasks = data.data.map((task: ApiCountTask) => {
          let assigned = false;
          let assigned_to_user = false;
          if (task.attributes.user_id !== null ) {
            assigned = true;
            if(task.attributes.user_id === rootGetters['core/getAuthUserId']) {
              assigned_to_user = true;
            }
          }

          return {
            id: task.id,
            due_date: task.attributes.due_date,
            huboo_sku: task.attributes.huboo_sku,
            hub_number: task.attributes.hub_number,
            discrepancy_margin: task.attributes.discrepancy_margin,
            location_name: task.attributes.location_name,
            status: task.attributes.status,
            user_id: task.attributes.user_id,
            assigned: assigned,
            assigned_to_user: assigned_to_user
          }
        })
        commit('setCountTasks', countTasks);
      }
      return true;
    } catch (err: unknown) {
      if (axios.isAxiosError(err) && (<AxiosError>err).response?.status === 404) {
        return false
      }
      await dispatch('core/handleError', err, {root: true})
      return false
    }
  },

  async startRecount({dispatch, commit, rootGetters, state}: ActionContext<CycleCountingStateInterface, StateInterface>): Promise<void> {
    commit('clearCountEvents')
    commit('setIsRecount', true)

    if(state.activeTaskId) {
      const userId = rootGetters["core/getAuthUserId"];
      await dispatch('updateTaskStatus', {task_id: state.activeTaskId, user_id: userId, status: CycleCountStatus.RECOUNT_IN_PROGRESS});
    }

    await dispatch('core/redirect', {name: 'count_summary'}, {root: true})
    await dispatch('core/addNotification',
      {
        type: NotificationTypes.NOTIFICATION_TYPE_INFO,
        message: i18n.t('cycle_counting.recount_needed'),
        timeout: (5000 * 60)
      },
      {root: true}
    )
  },

  async completeCount({dispatch, commit, rootGetters, state}: ActionContext<CycleCountingStateInterface, StateInterface>): Promise<void> {

    if(state.activeTaskId) {
      const userId = rootGetters["core/getAuthUserId"];
      await dispatch('updateTaskStatus', {task_id: state.activeTaskId, user_id: userId, status: CycleCountStatus.COMPLETE});
    }

    commit('resetState');
    await dispatch('core/redirect', {name: 'count_tasks'}, {root: true});
    await dispatch(
      'core/addNotification',
      {
        type: NotificationTypes.NOTIFICATION_TYPE_SUCCESS,
        message: i18n.t('cycle_counting.submit_success'),
        timeout: 3000
      },
      {root: true}
    );
  },

  startManualCount({dispatch, commit}: ActionContext<CycleCountingStateInterface, StateInterface>): void {
    commit('setActiveTaskId', null)
    dispatch('core/redirect', {name: 'start_manual_count'}, {root: true});
  },

  async startCountTask({dispatch, commit, rootGetters}: ActionContext<CycleCountingStateInterface, StateInterface>, payload: CountTask): Promise<boolean> {
    const stockForLocation = await dispatch('fetchStockForLocation', {
      name: payload.location_name,
      hubooSku: payload.huboo_sku
    })
    if (stockForLocation?.data) {
      const responseWithLocations: StockForLocation[] = stockForLocation.data.data.map((stock: StockForLocation) => {
        return {
          ...stock,
          attributes: {
            ...stock.attributes,
            location_name: payload.location_name
          }
        }
      })
      commit('setStocks', responseWithLocations)
    }
    commit('setLocation', payload.location_name)
    commit('setActiveTaskId', payload.id)
    const userId = rootGetters["core/getAuthUserId"];
    if (payload.status === CycleCountStatus.RECOUNT_IN_PROGRESS) {
      commit('setIsRecount', true)
    } else if (payload.status !== CycleCountStatus.IN_PROGRESS) {
      await dispatch('updateTaskStatus', {task_id: payload.id, user_id: userId, status: CycleCountStatus.IN_PROGRESS});
    }

    return true;
  },

  async updateTaskStatus({dispatch}: ActionContext<CycleCountingStateInterface, StateInterface>, payload: { task_id: number; user_id: number; status: CycleCountStatus; }): Promise<boolean> {

    const updateTaskStatusRequestData: updateTaskStatusRequest = {
      data: {
        type: 'stock-count-task',
        attributes: {
          user_id: payload.user_id,
          status: payload.status,
        }
      }
    };

    try {
      await api(
        'updateTaskStatus',
        {
          data: updateTaskStatusRequestData,
          handleError: false,
          inlineParams: {
            taskId: payload.task_id
          }
        }
      )
      return true
    } catch(err) {
      console.error(err)
      await dispatch('core/handleError', err, {root: true});
      return false
    }
  },

  async getProductsForBarcode(
      {dispatch}: ActionContext<CycleCountingStateInterface,
          StateInterface>,
      payload: { barcode: string; huboo_sku: number|null; }
  ): Promise<ProductAttributes[]|null> {
    try {
      const { data: body }: AxiosResponse<
        JsonApiResponseWithManyData<
          ProductAttributes,
          never
        >
      > = await api(
          'getProducts',
          {
            headers: {
              Accept: 'application/vnd.api+json'
            },
            params: {
              'filter[huboo_sku]': payload.huboo_sku,
              'filter[barcodes.barcode]': payload.barcode,
              'page[size]': 1000,
              'page[number]': 1
            },
            handleError: false
          }
      )
      return body.data.map((item) => item.attributes);

    } catch (err: unknown) {
      await dispatch('core/handleError', err, {root: true})
      return null
    }
  },
}
