
import ConfirmationChallenge from "@/components/order/ConfirmationChallenge.vue";
import LocationChallenge from "@/components/order/LocationChallenge.vue";
import MispickChallenge from "@/components/order/MispickChallenge.vue";
import QuantityChallenge from "@/components/order/QuantityChallenge.vue";
import BatchExpiryChallenge from "@/components/order/BatchExpiryChallenge.vue";
import BatchQuantityInput from "@/components/order/BatchQuantityInput.vue";
import BatchProductBarcodeVerification from "@/components/order/BatchProductBarcodeVerification.vue";
import { bus } from "@/bootstrap/bus";
import auditLog from "@/bootstrap/auditLog";
import lodash from "lodash";
import { HapticFeedbackEvents } from "@/bootstrap/HapticFeedbackConfig";
import { isContainer, isPackageIdentifier } from "@/utilities/CodeIdentifier";
import {defineComponent} from "vue";
import { OrderItem } from "@/types/OrderItem";
import { Batch } from "@/types/Batch";
import OrderItemCollection from '@/collection/OrderItemCollection';
import RecalledChallenge from "@/components/order/RecalledChallenge.vue";
import { RecallStatus, recalledStatuses} from "@/types/RecallStatus";
import { ContainerPickState, containerPickActions } from "@/store";
import { Order } from '@/types/Order';
import { TranslateResult } from 'vue-i18n';
import moment from "moment";
import extractQrElements from '@/utilities/ScanHandlers';

export default defineComponent({
  name: "PickingItems",
  data: () => ({
    lazyImageSource:
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
    quantityChallenge: false as boolean | number,
    showBatchQuantityInput: false as boolean,
    mispickChallenge: false as boolean | string,
    confirmationChallenge: false as boolean | string,
    locationChallenge: false as boolean | OrderItem[],
    batchExpiryChallenge: false as boolean,
    batchProductBarcodeVerification: false as boolean,
    recalledChallenge: false as boolean,
    currentOrderItem: null as OrderItem | null,
    currentBatchId: null as number | null,
    currentBatchReference: null as string | null,
    currentSku: null as number | null,
    loading: false as boolean,
    loadingMessage: null as TranslateResult | null,
    itemsForSku: new OrderItemCollection(),
    scanResult: null as string | null,
    scanResultCount: 0
  }),
  components: {
    ConfirmationChallenge,
    LocationChallenge,
    MispickChallenge,
    QuantityChallenge,
    BatchQuantityInput,
    BatchExpiryChallenge,
    BatchProductBarcodeVerification,
    RecalledChallenge
  },
  computed: {
    order(): Order {
      return this.$store.state.picking.order;
    },
    isBatchedClient(): boolean {
      return this.order.batchStatus === 'ENABLED';
    },
    items(): OrderItem[] {
      const items: OrderItem[] = this.$store.state.picking.order.items;
      items.sort((a, b) => {
        const aPicked = this.isItemPicked(a.id);
        const bPicked = this.isItemPicked(b.id);
        if (aPicked === bPicked && a.location) {
          return a.location.localeCompare(b.location);
        }
        return aPicked > bPicked ? 1 : -1;
      });
      return items;
    },
    isItemPicked(): (items: number) => boolean {
      return (itemId: number) => {
        return this.$store.state.picking.picked.indexOf(itemId) !== -1;
      }
    },
    getLocation() {
      return (item: OrderItem) => {
        let location = "";
        if (item.hubId !== parseInt(this.$store.state.core.hubId)) {
          location += item.hubId + " - ";
        }
        location += item.location;
        return location;
      };
    },
    capitalize() {
      return (value: string) => lodash.capitalize(value);
    },
    isChallenged() {
      return (
        this.quantityChallenge ||
        this.mispickChallenge ||
        this.confirmationChallenge ||
        this.locationChallenge ||
        this.batchProductBarcodeVerification ||
        this.recalledChallenge
      );
    },
    displayQuantity() {
      return (item: OrderItem) => {
        if (item.quantity > 1 && item.batches.length) {
          return `${item.batches.length}/${item.quantity}`;
        }

        return `${item.quantityPicked}/${item.quantity}`;
      }
    },
    calculateMaxBatchQuantityInput(): number {
      return this.currentOrderItem ? this.currentOrderItem.quantity - this.getOrderPickedBatchTotal(this.currentOrderItem) : 0;
    },
    getAvailableBatchQuantity(): number | undefined {
      if (!this.currentOrderItem?.batches_for_location) return undefined;
      const batchQuantity = this.currentOrderItem?.batches_for_location?.find(
          (batch) => batch.batch_id === this.currentBatchId
        )?.quantity ?? 0
      const availableBatchQuantity = batchQuantity - this.getPickedTotalByBatch(this.currentOrderItem, this.currentBatchId);
      return Math.max(availableBatchQuantity, 0);
    },
    displayMinExpiryDate() {
      return (item: OrderItem) => item.batch_min_expiry_date
          ? item.batch_min_expiry_date.toLocaleDateString(navigator.language)
          : null;
    },
    containerScanValidation(): boolean {
      return this.$store.getters['containerPick/getContainerScanValidation'];
    },
    unpickedTasksCount(): number {
      return this.$store.getters["picking/unpickedItems"].length
    }
  },
  created() {
    bus.$on("barcodeScanned", this.handleItemScan);
  },
  beforeDestroy() {
    bus.$off("barcodeScanned", this.handleItemScan);
  },
  watch: {
    containerScanValidation(value: ContainerPickState['containerScanValidation']) {
      this.mispickChallenge = Object.entries(value).map(([validationName, result]) => {
        const {failed, content} = result
        return failed ? this.$t(`validation.container_scan.${validationName}`, content) + '\n' : ''
      }).join('')
    },
    async unpickedTasksCount(tasksCount: number) {
      if (tasksCount === 0) {
        this.currentBatchId = null;
        this.setCurrentOrderItem(null);
        this.loading = true;
        auditLog.logStartPacking(this.$store.state.picking.order);
        await this.$store.dispatch("picking/setOrderInProgress");
        this.loading = false;
        await this.$store.dispatch("picking/transitionState");
      }
    }
  },
  methods: {
    // HANDLERS
    handleItemScan(scanResult: string): void {
      this.scanResult = scanResult;
      ++this.scanResultCount;
      // Do not confirm picks until all challenges are passed or closed
      if (this.isChallenged) {
        return;
      }
      if (this.isContainerScan(scanResult)) {
        if (!this.loading) {
          this.loading = true;
          this.loadingMessage = this.$t('loading_containers')
          this.$store
            .dispatch(containerPickActions.ON_CONTAINER_SCAN, {
              containerLabel: scanResult,
            })
            .finally(() => {
              this.loadingMessage = null;
              this.loading = false;
            });
        }
        return;
      }

      if (isPackageIdentifier(this.scanResult)) {
        this.$emit("updatePackageIdentifier", this.scanResult);
        return;
      }
      const [sku = null, batchId = null] = extractQrElements(this.scanResult);
      // Only ever treat the current pick as a batch if the client is batch enabled, else treat this as a normal pick
      this.currentBatchId = batchId && this.isBatchedClient ? parseInt(batchId) : null;
      this.currentSku = sku ? +sku : null;
      this.itemsForSku = new OrderItemCollection(
          sku ? this.extractOrderItemsBySku(+sku) : this.extractOrderItemsByBarcode(this.scanResult),
          this.$store.state.picking.picked
      );
      
      this.handleItemPick(this.scanResult);
    },
    // Per SKU, items is for SKU
    handleItemPick: function (scanResult: string): void {
      // Item does not belong to the current order, sku not there
      if (!this.isSkuInOrderItems()) {
        this.challengeIncorrectScan(scanResult, this.currentSku, this.currentBatchId);
        return;
      }
      // All items with the same sku are already picked, based on task id
      if (!this.hasOrderItemsAvailableToPick()) {
        this.challengeAlreadyPicked(this.currentSku?.toString() || scanResult);
        return;
      }
      // If a batch scan and we have no batch tasks to pick from
      if (this.isBatchScanForNonBatchItem()) {
        this.challengeBatchScanOfNonBatchItem();
        return;
      }

      // If using a 2D barcode then cannot use for batched, can only use QR codes
      if (this.isBatchedClient && this.hasBatchedOrderItemsScannedByBarcode()) {
        this.challengeBarcodeScanOfBatchedItem();
        return;
      }
      
      // if client batched enabled and is_batch === 1 then only batch scans
      // if client batched enabled and is_batch === 0 then non batch scans only
      // if client transitioning then non batch and batch scans
      // if not batched then non batch scans only

      // If no batch on scan, but the product is batched
      if (this.isBatchedClient && this.isNonBatchScanForBatchItem()) {
        this.challengeNonBatchScanOfBatchedItem();
        return;
      }
      // No batches left to scan so invalid batch scan
      if (this.isBatchScanWithoutBatchesToPick()) {
        this.challengeMismatchedLocationOfBatchedItem();
        return;
      }
      // There are multiple items still not picked with the same sku from different locations
      if (this.isOrderItemInMultipleLocations()) {
        // Chose location you're picking from
        this.challengeLocation(this.getUniqueValidBatchLocationsForOrderItems());
        return;
      }
      // If using batch and barcode scan enabled then bypass batch quantity input and scan each product
      if (this.isBatchProductBarcodeVerificationRequired()) {
        this.verifyBatchProductBarcode();
        return;
      }

      const currentOrderItem = this.getCurrentOrderItem();
      const challengesPass = this.setCurrentOrderItem(currentOrderItem);
      if (challengesPass) {
        this.pick();
      }
    },
    async markCurrentItemAsPicked(): Promise<void> {
      if (this.currentOrderItem) {
        auditLog.logItemScan(this.scanResult, this.currentSku, this.currentBatchId);
        this.$store.commit("picking/markItemAsPicked", {taskId: this.currentOrderItem.id});
      }
    },
    closeChallenges(): void {
      this.confirmationChallenge = false;
      this.locationChallenge = false;
      this.mispickChallenge = false;
      this.quantityChallenge = false;
      this.showBatchQuantityInput = false;
      this.batchExpiryChallenge = false;
      this.batchProductBarcodeVerification = false;
      this.recalledChallenge = false;
      this.setCurrentOrderItem(null);
      this.currentBatchId = null;
      this.currentBatchReference = null;
    },
    // After quantity confirmed on modal on pick
    passQuantityChallenge(): void {
      this.quantityChallenge = false;
      this.markCurrentItemAsPicked();
    },
    // After batch quantity modal confirmed on pick
    onBatchQuantityInput(quantity: number) {
      if (!this.currentOrderItem) return;

      const pickedQuantity = +quantity + this.getOrderPickedBatchTotal(this.currentOrderItem);

      this.storeBatch(quantity);

      if (pickedQuantity === this.currentOrderItem.quantity){
        this.markCurrentItemAsPicked();
      }
      this.showBatchQuantityInput = false;
    },
    // multiple locations
    passLocationChallenge(item: OrderItem): void {
      this.locationChallenge = false;
      const challengesPass = this.setCurrentOrderItem(item);
      if (challengesPass) {
        this.pick();
      }
    },
    // Manual pick
    passConfirmationChallenge(reason: string): void {
      this.confirmationChallenge = false;
      if (this.currentOrderItem?.sku) auditLog.logManualPick(+this.currentOrderItem.sku, reason);
      this.pick();
    },
    pick(): void {
      if (!this.validateQuantity()) {
        return;
      }
      this.markCurrentItemAsPicked();
    },
    onBatchProductBarcodeVerification(quantity: number):void {
      if (!this.currentOrderItem) return;

      const pickedQuantity = +quantity + this.getOrderPickedBatchTotal(this.currentOrderItem);

      this.storeBatch(quantity);

      if (pickedQuantity === this.currentOrderItem.quantity){
        this.markCurrentItemAsPicked();
      }

      this.batchProductBarcodeVerification = false;
    },
    verifyBatchProductBarcode() {
      this.batchProductBarcodeVerification = true;
    },
    // GETTERS
    getCurrentOrderItem(): OrderItem {
      const currentOrderItem = this.currentBatchId
        ? this.itemsForSku.filterByBatchId(this.currentBatchId).getFirstUnpicked()
        : this.itemsForSku.getFirstUnpicked();

      if (!currentOrderItem) return this.itemsForSku.toArray()[0];

      return currentOrderItem;
    },
    getPickedBatchDetails(): Batch|number|undefined {
      return this.itemsForSku.filterUnpicked().toArray()
          .flatMap((item) => item.batches_for_location)
          .find((batch) => batch?.batch_id === this.currentBatchId);
    },
    getOrderPickedBatchTotal(orderItem: OrderItem): number {
      return orderItem?.batches?.length ?? 0;
    },
    getPickedTotalByBatch(orderItem: OrderItem|null, batchId: number|null): number {
      return orderItem?.batches?.filter((batch) => batch === batchId).length ?? 0;
    },
    getUniqueValidBatchLocationsForOrderItems(): OrderItem[] {
      const locations = new Map();
      const items = this.currentBatchId ?
          this.itemsForSku.filterUnpicked().filterByBatchId(this.currentBatchId) :
          this.itemsForSku.filterUnpicked();

      return items.toArray().filter((item: OrderItem) => {
            const key = `${item.hubId}_${item.location}`;
            if (locations.has(key)) {
              return false;
            }
            locations.set(key, key)
            return true;
          });
    },
    // SETTERS
    setCurrentOrderItem(orderItem: OrderItem | null): boolean {
      this.currentOrderItem = orderItem;
      if (this.currentOrderItem && this.scanResult) {
        if (this.currentBatchId && this.getAvailableBatchQuantity === 0) {
          this.challengeRemainingQuantityOfBatch()
          return false
        }
        const pickedBatch = this.getPickedBatchDetails();

        if (!pickedBatch || (typeof pickedBatch === 'number')) {
            return true;
        }
        this.currentBatchReference = pickedBatch?.reference;

        if (this.recalled(pickedBatch)) {
          this.challengeQuarantined(this.scanResult, pickedBatch);
          return false;
        }

        if (!this.validateBatchExpiry(pickedBatch)) {
          this.challengeBatchExpiry(this.scanResult, pickedBatch.batch_id);
          return false;
        }
      }

      return true;
    },
    storeBatch: function (quantity: number) {
      if (this.currentOrderItem && this.currentBatchId) {
        this.$store.commit('picking/storeBatch', {
          'itemId': this.currentOrderItem.id,
          'batchId': this.currentBatchId,
          'quantity': quantity,
        })
      }
    },
     // CHECKS
    isSkuInOrderItems(): boolean {
      return this.itemsForSku.count() > 0;
    },
    hasOrderItemsAvailableToPick(): boolean {
      return this.itemsForSku.filterPicked().count() < this.itemsForSku.count();
    },
    hasBatchedOrderItemsScannedByBarcode(): boolean {
      return this.itemsForSku.filterBatchedScannedByBarcode().count() > 0 && !this.batchProductBarcodeVerification;
    },
    isNonBatchScanForBatchItem(): boolean {
      return !this.currentBatchId &&
        this.itemsForSku.hasBatched();
    },
    isBatchScanForNonBatchItem(): boolean {
      return !!this.currentBatchId && !this.itemsForSku.hasBatched();
    },
    isOrderItemInMultipleLocations(): boolean {
      return this.getUniqueValidBatchLocationsForOrderItems().length > 1;
    },
    isBatchScanWithoutBatchesToPick(): boolean {
      return !!this.currentBatchId && !this.itemsForSku.filterUnpicked().hasBatchId(this.currentBatchId);
    },
    isBatchProductBarcodeVerificationRequired(): boolean {
      return !!this.currentBatchId 
        && this.$store.getters['picking/isBatchProductBarcodeVerificationRequired'] 
        && this.setCurrentOrderItem(this.getCurrentOrderItem())
    },
    isContainerScan(scanResult: string): boolean {
      return this.order.customer_type === 'B2B' && isContainer(scanResult)
    },
    isBatchedOrderItem(orderItem: OrderItem | null): boolean {
      return !!orderItem?.is_batched && this.isBatchedClient;
    },
    // Manual pick
    checkManualPick(item: OrderItem): void {
      this.setCurrentOrderItem(item);
      if (this.isItemPicked(item.id)) {
        this.challengeAlreadyPicked(item?.sku?.toString());
        return;
      }
      // Items with batches are not supposed to be picked manually - show warning
      if (this.isBatchedOrderItem(this.currentOrderItem)) {
        this.challengeManualPickOfBatchedItem();
        return;
      }
      this.confirmationChallenge = item.checkCode;
    },
    validateBatchExpiry(pickedBatch: Batch): boolean {
      const taskMinExpiryDate = this.currentOrderItem?.batch_min_expiry_date;
      const batchExpiryDate = pickedBatch?.bbe_date;
      const today = moment().startOf('day').toDate()
      const meetsTaskMinExpiryDate = !taskMinExpiryDate || (!!batchExpiryDate && batchExpiryDate > taskMinExpiryDate);
      const batchExpired = batchExpiryDate && batchExpiryDate <= today;
      return (
        meetsTaskMinExpiryDate && !batchExpired
      );
    },
    validateQuantity(): boolean {
      if (this.batchExpiryChallenge) return false;
      if (!this.currentOrderItem) return false;
      const numPickedBatchUnits = this.currentOrderItem.batches?.length ?? 0;

      const remainingUnitsToPick = this.currentOrderItem.quantity - numPickedBatchUnits;

      if (remainingUnitsToPick === 1) {
        if (this.isBatchedOrderItem(this.currentOrderItem)){
          this.storeBatch(1);
        }

        return true;
      }

      if (this.currentOrderItem.quantity > 1 && !this.isBatchedOrderItem(this.currentOrderItem)) {
        this.quantityChallenge = this.currentOrderItem.quantity;
        return false;
      }

      this.showBatchQuantityInput = true;
      return false;
    },
    recalled(pickedBatch: Batch): boolean {
      return Object.values<RecallStatus>(recalledStatuses).includes(pickedBatch.recall_status);
    },
    // CHALLENGES
    challengeIncorrectScan(scanResult: string, sku: number | null, batchId: number | null): void {
      this.mispickChallenge = this.$t("invalid_barcode_scan") as string;
      auditLog.logMispick(scanResult, sku, batchId);
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeAlreadyPicked(scanResult: string): void {
      this.mispickChallenge = this.$t("item_already_picked") as string;
      this.mispickChallenge += "\n\r";
      this.mispickChallenge += scanResult;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeManualPickOfBatchedItem(): void {
      this.mispickChallenge = this.$t("batched_item_cannot_be_manually_picked") as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeBarcodeScanOfBatchedItem(): void {
      this.mispickChallenge = this.$t(
        "batched_item_cannot_be_scanned_by_barcode"
      ) as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeMismatchedLocationOfBatchedItem(): void {
      this.mispickChallenge = this.$t("batched_item_location_mismatch") as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeNonBatchScanOfBatchedItem(): void {
      this.mispickChallenge = this.$t("batched_item_invalid_qr_code") as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeRemainingQuantityOfBatch(): void {
      this.mispickChallenge = this.$t("batched_item_no_stock_at_location") as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeBatchScanOfNonBatchItem():void {
      this.mispickChallenge = this.$t("batch_scan_on_non_batch_item") as string;
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeLocation(items: OrderItem[]): void {
      this.locationChallenge = items;
    },
    challengeBatchExpiry(scanResult: string, batchId: number): void {
      this.batchExpiryChallenge = true;
      auditLog.logInvalidBatchScan(scanResult, batchId);
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    challengeQuarantined(scanResult: string, batch: Batch): void {
      this.recalledChallenge = true;
      auditLog.logRecalledBatchScan(scanResult, batch);
      bus.$emit(HapticFeedbackEvents.EVENT_WARNING);
    },
    // HELPERS
    extractOrderItemsBySku: function (sku: number): OrderItem[] {
      return this.items.filter((item: OrderItem) => {
        this.$store.commit("picking/resetScannedBarcode");
        return sku.toString() === item.sku.toString();
      });
    },
    extractOrderItemsByBarcode: function (scanResult: string): OrderItem[] {
      return this.items.filter(
          (i: OrderItem) =>
              (i.scannedByBarcode =
                  i.barcodes.includes(scanResult))
      );
    },
  },
});
