import { api } from "./api";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { createPromiseManager } from "@/utilities/promiseManager";
import config from "@/api/config";

export interface ApiRequestConfig extends Omit<AxiosRequestConfig, "auth"> {
  auth?: boolean;
  handleError?: boolean;
  inlineParams?: AxiosRequestConfig['params']
}

export type EndpointType = keyof (typeof config)["endpoints"];

export const concurrentJsonApi = async <T>(
  endpointUrl: EndpointType,
  config: ApiRequestConfig[],
  concurrencyLimit: number
): Promise<AxiosResponse<T>[]> => {
  return await createPromiseManager<AxiosResponse>()
    .setPromises(config.map((request) => api(endpointUrl, request)))
    .setConcurrency(concurrencyLimit)
    .resolve();
};
