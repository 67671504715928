<template>
  <v-dialog
      :value="true"
      @input="$emit('close')"
      max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('which_location_item_picked') }}?
      </v-card-title>
      <v-card-text>
        <v-btn
            v-for="item in items"
            :key="item.id"
            block
            class="mb-3"
            x-large
            @click="submit(item)"
        >
          {{ getLocation(item) }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            id="item_confirmation_dialog_close_btn"
            color="accent"
            @click="$emit('close')"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LocationChallenge',
  data: () => ({}),
  computed: {
    getLocation() {
      return function (item) {
        let location = ''
        if (item.hubId !== this.$store.state.core.hubId) {
          location += item.hubId + ' - '
        }
        location += item.location
        return location
      }
    },
  },
  props: {
    items: {
      required: true,
      type: [Boolean, Array],
    }
  },
  methods: {
    submit(item) {
      this.$emit('pass', item)
    }
  }
}
</script>