<template>
  <v-overlay
      class="text-center">
    <template v-if="loadingPackageIdentifier">
      <v-progress-circular indeterminate/>
      <p>
        {{ $t('loading') }}
      </p>
    </template>
    <template v-if="error">
      <h1 class="mb-3 error-message">
        {{ error }}
      </h1>
      <v-btn
          id="package_identifier_error_close_btn"
          class="white--text"
          color="error"
          @click="handleClose()"
      >
        {{ $t('close') }}
      </v-btn>
    </template>
    <v-dialog
        :value="challengeOverride"
        @input="$emit('close')"
        max-width="500"
    >
      <v-card>
        <v-form @submit.prevent="forceHandlePackageIdentifier">
          <v-card-title class="headline">
            {{ $t('overwrite_package_identifier_prompt') }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                id="challenge_update_package_identifier_cancel"
                color="accent"
                @click="$emit('close')"
            >
              {{ $t('No') }}
            </v-btn>
            <v-btn
                id="challenge_update_package_identifier_confirm"
                type="submit"
                color="secondary"
                @click="forceHandlePackageIdentifier"
            >
              {{ $t('Yes') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import NotificationTypes from "@/enums/NotificationTypes";
import auditLog from "@/bootstrap/auditLog";

export default {
  name: 'PackageIdentifierManager',
  components: {},
  props: {
    packageIdentifier: {
      required: true,
      type: String
    }
  },
  created () {
    this.handlePackageIdentifier();
  },
  data: () => ({
    loadingPackageIdentifier: false,
    error: null,
    challengeOverride: false,
  }),
  methods: {
    async forceHandlePackageIdentifier () {
      this.challengeOverride = false;
      await this.handlePackageIdentifier(true);
    },
    async handlePackageIdentifier (force) {
      if (typeof force === 'undefined') {
        force = false;
      }
      this.loadingPackageIdentifier = true;
      try {
        await this.$store.dispatch(
            'picking/updatePackageIdentifier',
            {
              packageIdentifier: this.packageIdentifier,
              force: force
            }
        )
        if (this.$store.getters['picking/getOrderPackageIdentifier'] !== this.packageIdentifier) {
          this.challengeOverride = true;
        } else {
          this.$store.commit('picking/setPackageIdentifier', this.packageIdentifier);
          await this.$store.dispatch('core/addNotification', {
            message: this.$t('notification_package_identifier_updated'),
            type: NotificationTypes.NOTIFICATION_TYPE_SUCCESS,
            timeout: 3500,
          });
          auditLog.logPILabelAssociation(this.packageIdentifier);
          this.$emit('close');
        }
      } catch (e) {
        if (e?.response?.status === 422) {
          if (e.response.data.errors?.package_identifier) {
            this.error = e.response.data.errors?.package_identifier[0];
          } else {
            this.error = e.response.data.message;
          }
        } else {
          this.$store.dispatch('core/handleError', e)
        }
      }
      this.loadingPackageIdentifier = false;
    },
    handleClose () {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.error-message {
  white-space: pre-wrap;
}
</style>
