import {bus} from "@/bootstrap/bus"

class BarcodeScanner {
    scannerInputReset = 10
    timeoutHandler = 0
    returnCharacterCode = 13
    inputString = ''
    initialize = (latency) => {
        this.scannerInputReset = latency
        document.addEventListener('keypress', this.keyup)
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
    }
    keyup = (e) => {
        // we do not want to override default scanner functionality - acting as native keyboard
        if (document.activeElement.tagName.toLowerCase() === 'input') {
            return;
        }
        if (this.timeoutHandler) {
            clearTimeout(this.timeoutHandler)
        }
        this.inputString += String.fromCharCode(e.keyCode)
        this.timeoutHandler = setTimeout(() => this.validateAndSubmitScan(), this.scannerInputReset)
    }
    validateAndSubmitScan() {
        if (this.isNotValidScan()) {
            this.resetInputString()
            return
        }
        this.inputString = this.inputString.toString().replace(String.fromCharCode(this.returnCharacterCode), '')
        bus.$emit('barcodeScanned', this.inputString)
        this.resetInputString()
    }
    resetInputString() {
        this.inputString = ''
    }
    isNotValidScan() {
        return this.inputString.length <= 2
    }
}

export default new BarcodeScanner()
