
import {defineComponent} from 'vue'
import { datadogRum } from "@datadog/browser-rum"

export default defineComponent({
  name: 'Logout',
  mounted() {
    this.$store.dispatch('core/logout', {name: 'hub_selector'})
    datadogRum.clearUser();
  }
})
