import {api} from "@/bootstrap/api";
import {ORDER_STEP_ID_COMPLETE} from "@/bootstrap/config";
import {AbandonPayload} from "@/utilities/AbandonmentReasons";

export default {
    setAsInProgress(orderId: number) {
        return api(
            'pickOrder',
            {
                data: {
                    batchDetails: [],
                    storedProductDetails: [],
                },
                params: {
                    order_id: orderId,
                }
            }
        )
    },
    setAsPickComplete(orderId: number, packageIdentifier: string) {
        return api(
            'patchOrder',
            {
                data: {
                    package_identifier: packageIdentifier,
                    order_header_step_id: ORDER_STEP_ID_COMPLETE
                },
                params: {
                    order_id: orderId,
                },
            },
        )
    },
    async abandonOrder ({order_id, abandonment_reason, huboo_sku}: AbandonPayload) {
      await api('abandonOrder', {
        params: { order_id },
        data: { abandonment_reason, huboo_sku }
      });
    },
}
