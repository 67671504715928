
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'ProductTile',
  data: () => ({
    loadingProduct: true as boolean,
    lazyImageSource: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
  }),
  props: {
    batchReference: {
      required: false,
      default: '--',
      type: String
    },
    batchBbeDate: {
      required: false,
      default: '--',
      type: String,
    },
    hubooSku: {
      required: true,
      type: Number
    },
    locationName: {
      required: true,
      type: String
    },
    title: {
      required: false,
      default: '--',
      type: String
    },
    itemImageUrl: {
      required: false,
      type: String
    },
    showBatch: {
      required: false,
      type: Boolean,
      default: true,
    }
  },
  computed: {
    displayProductAttributes(): Record<string, (string | number)> {
      const date = new Date(this.batchBbeDate);
      const formatted_date = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}`
      return {
        "due_date": formatted_date,
        "batch_ref": this.batchReference,
        "location_name": this.locationName,
        "huboo_sku": this.hubooSku,
        "title": this.title,
      }
    },
  },
})
