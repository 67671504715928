export default function isDateTomorrow(utcDate: string | null): boolean {
  if (!utcDate) 
    return false;
  
  const nowInLocalTime = new Date();
  const utcDateConvertedToLocalTime = new Date(utcDate); 

  return removeTime(nowInLocalTime) < removeTime(utcDateConvertedToLocalTime);
}

function removeTime(date: Date): Date {
  return new Date(
    date.getFullYear(), 
    date.getMonth(), 
    date.getDate());
}