import { render, staticRenderFns } from "./ProductTile.vue?vue&type=template&id=72e1f047&scoped=true&"
import script from "./ProductTile.vue?vue&type=script&lang=ts&"
export * from "./ProductTile.vue?vue&type=script&lang=ts&"
import style0 from "./ProductTile.vue?vue&type=style&index=0&id=72e1f047&prod&scoped=true&lang=css&"
import style1 from "./ProductTile.vue?vue&type=style&index=1&id=72e1f047&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e1f047",
  null
  
)

export default component.exports