import Vue from 'vue'
import Vuex from 'vuex'
import core from '@/store/core'
import orders, {OrdersStateInterface} from '@/store/orders'
import picking from '@/store/picking'
import cycleCounting, { CycleCountingStateInterface } from '@/store/cycleCounting'
import createPersistedState from 'vuex-persistedstate'
import {Moment} from "moment";
import containerPick, {
    ContainerPickState,
} from '@/store/containerPick'

Vue.use(Vuex)

export interface CoreStateInterface {
    accessToken: null | string,
    accessTokenExpiresAt: null | Date | string,
    hubId: null | number,
    authUser: null | object,
    errors: [],
    title: null | string,
    activeSessionTime: null | Moment,
    redirectToStartPickWithBarcode: boolean,
}

export interface StateInterface {
    core: CoreStateInterface,
    orders: OrdersStateInterface,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    picking: any,
    containerPick: ContainerPickState,
    cycleCounting: CycleCountingStateInterface,
}

const store = new Vuex.Store<StateInterface>({
        modules: {
            core: core,
            orders: orders,
            picking: picking,
            containerPick,
            cycleCounting,
        },
        plugins: [
            createPersistedState()
        ]
    }
)

export default store
