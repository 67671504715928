
import {defineComponent} from 'vue'
import {TranslateResult} from 'vue-i18n'

export default defineComponent({
  name: "StartManualCount",
  data: () => ({
    locationName: null as string | null,
    error: null as TranslateResult | null,
    submitLoading: false as boolean
  }),
  computed: {
    Location(): TranslateResult {
      return this.$t('cycle_counting.enter_a_location')
    },
    canSubmit(): boolean {
      return !!this.locationName && !this.submitLoading
    },
    getStateLocation(): string | null {
     return this.$store.getters['cycleCounting/getLocation']
    },
    errors(): TranslateResult | null {
      return this.error
    },
  },
  methods: {
    async recordLocation(): Promise<void> {
      this.submitLoading = true
      const locationExists = await this.$store.dispatch('cycleCounting/checkLocationName', this.locationName)

      if(!(typeof locationExists === 'boolean')) {
        this.submitLoading = false
        return
      }
      if (locationExists) {
        // trigger dispatch to get system stock quantities
        const stockForLocation = await this.$store.dispatch('cycleCounting/fetchStockForLocation', {name: this.locationName, hubooSku: null})
        if(stockForLocation?.data) {
          this.$store.commit('cycleCounting/setStocks', stockForLocation.data.data)
        }

        this.$store.commit('cycleCounting/setLocation', this.locationName)
        this.submitLoading = false
        return this.$store.dispatch('core/redirect', {name: 'count_summary'})
      }
      this.error = this.$t('validation.cycle_counting.location_not_found')
      this.submitLoading = false
    },
    resetError(): void {
      this.error = null
    }
  },
})
