import {ref, computed} from "vue";
import { ChallengeTypes } from  '../tasks/types'

export type Challenge<U> = {
  id: number,
  type: ChallengeTypes | null,
  passed: boolean,
  errorMessages: string[],
  attributes: U,
}

export const useChallenge = <U>() => {
  const state = ref<Challenge<U>>({
    id: 0,
    type: null,
    passed: false,
    errorMessages: [],
    attributes: {} as U,
  });

  const getters = {
   getChallenge : computed(() => state.value),
   getPassedStatus: computed(() => state.value.passed),
   getErrorMessages: computed(() => state.value.errorMessages),
  }

  function setErrorMessages(errorMessages: string[]): void {
    state.value.errorMessages = errorMessages;
  }

  return {
    state,
    actions: {
      setErrorMessages,
    },
    getters
  }
}
