import { PickTask } from '@/stores/tasks/pick-tasks';
import { TaskStatus, TaskTypes } from '@/stores/tasks/types';
import {
  ApiHubooBox,
  ApiOrderLine,
  ApiProduct,
  ApiShipment,
  ApiSmartOrders,
  ApiTask,
  SmartOrderMapped } from '@/types/ApiSmartOrders';
import { MappedOrderLines, Tote } from '@/types/WavePicking';
import { mapBatches } from './mapOrder';
import { EMPTY_VARIATION } from '@/bootstrap/config';

export function mapWavePickingTask(item: MappedOrderLines, tote?: Tote): PickTask {
  const t: Tote = tote || { id: 999999, name: '', userId: 0, orderHeaderId: 0 }
  const taskId = Math.floor(Math.random() * 9999999);
  return {
    id: taskId,
    type: TaskTypes.PICK,
    status: TaskStatus.NEW,
    attributes: {
      location: item.location,
      sku: item.sku,
      title: item.title,
      image: item.image,
      quantity: item.quantity,
      batch_min_expiry_date: item.batch_min_expiry_date,
      variations: item.variations as unknown as { [key: string]: string; },
      hubId: item.hubId,
      batches: item.batches,
      quantityPicked: item.quantityPicked,
      barcodes: item.barcodes,
      toteId: t.id,
      toteName: t.name,
      id: taskId
    },
    challenges: [],
    meta: {
      user: null,
      timings: {
        start: null,
        end: null
      },
    }
  }
}


export function mapSmartOrders(smartOrders: ApiSmartOrders): SmartOrderMapped[] {
  const mappedOrders = smartOrders.data.map(smartOrder => {
    // find all order lines associates with this smartOrder
    const orderLineIds = smartOrder.relationships.order_lines.data.map(d => d.id)
    const relatedOrderLines: ApiOrderLine[] = getApiIncludesByIdsAndType(smartOrders.included, orderLineIds, 'order-line') as ApiOrderLine[]

    const hubooboxId = smartOrder.relationships.huboo_box.data.id
    const relatedHubooboxes: ApiHubooBox[] = getApiIncludesByIdsAndType(smartOrders.included, [hubooboxId], 'huboo-box') as ApiHubooBox[]

    const shipmentId = smartOrder.relationships.shipment.data.id
    const relatedShipments: ApiShipment[] = getApiIncludesByIdsAndType(smartOrders.included, [shipmentId], 'shipment') as ApiShipment[]

    const mappedItems: MappedOrderLines[] = [];
    relatedOrderLines.forEach((orderLine) => {
      const taskIds = orderLine.relationships.tasks.data.map(d => d.id)
      const relatedTasks: ApiTask[] = getApiIncludesByIdsAndType(smartOrders.included, taskIds, 'task') as ApiTask[];

      const productId = orderLine.relationships.product.data.id
      const relatedProducts: ApiProduct[] = getApiIncludesByIdsAndType(smartOrders.included, [productId], 'product') as ApiProduct[];

      if (relatedTasks) {
        relatedTasks.forEach((task) => {
          const item = {
            orderLineId: Number(orderLine.id),
            barcodes: relatedProducts[0].attributes.barcodes.map((b) => b.barcode),
            checkCode: ('00' + (orderLine.attributes.sku_id % 999)).slice(-3), // check purpose of this field (used in single pick)
            hubId: task.attributes.hub_id,
            id: Number(task.id),
            image: relatedProducts[0].attributes.eBay_pictureURL,
            location: task.attributes.from_loc,
            quantity: task.attributes.qty_to_move,
            sku: orderLine.attributes.sku_id.toString(),
            title: relatedProducts[0].attributes.eBay_item_title,
            batches_for_location: mapBatches(task.attributes.batches_for_location),
            batch_min_expiry_date: task.attributes.batch_min_expiry_date ? new Date(task.attributes.batch_min_expiry_date) : null,
            variations: extractVariations(relatedProducts[0]),
            batches: [],
            in_bundle: orderLine.attributes.in_bundle,
            quantityPicked: 0,
            is_batched: relatedProducts[0].attributes.is_batched ? 1 : 0
          }
          mappedItems.push(item)
        })
      }
    })

    return {
      id: Number(smartOrder.id),
      batchStatus: relatedHubooboxes[0].attributes.batch_status,
      courier: smartOrder.attributes.tracking_code?.carrier ?? relatedShipments[0].attributes.courier,
      customer: relatedHubooboxes[0].attributes.hubpic_user.huboobox,
      customer_type: smartOrder.attributes.customer_type,
      inStock: smartOrder.attributes.in_stock,
      orderType: smartOrder.attributes.work_group,
      prime_delivery: smartOrder.attributes.prime_delivery ? 1 : 0,
      shipByTime: smartOrder.attributes.ship_by_time,
      shipmentId: smartOrder.attributes.shipment_id,
      userId: smartOrder.attributes.in_progress_by_user_id,
      status: smartOrder.attributes.status,
      items: mappedItems,
      bundles: smartOrder.attributes.bundles,
    }
  })
  return mappedOrders;
}

export function getApiIncludesByIdsAndType (
  includedArray: Array<ApiTask | ApiProduct | ApiOrderLine | ApiHubooBox | ApiShipment>,
  ids: string[],
  type: string
): Array<ApiTask | ApiProduct | ApiOrderLine | ApiHubooBox | ApiShipment> {
  return includedArray.filter((i) => ids.includes(i.id) && i.type === type)
}


function extractVariations(product: ApiProduct) {
  const variations: Record<string, string> = {}
  if (product) {
    const productAttributes = product.attributes
    if (
      productAttributes.variation_name &&
      productAttributes.variation_name !== EMPTY_VARIATION
    ) {
      variations[productAttributes.variation_name] = productAttributes.variation_value
    }
    if (
      productAttributes.variation_name2 &&
      productAttributes.variation_name2 !== EMPTY_VARIATION
    ) {
      variations[productAttributes.variation_name2] = productAttributes.variation_value2
    }
    if (
      productAttributes.variation_name3 &&
      productAttributes.variation_name3 !== EMPTY_VARIATION
    ) {
      variations[productAttributes.variation_name3] = productAttributes.variation_value3
    }
    if (
      productAttributes.variation_name4 &&
      productAttributes.variation_name4 !== EMPTY_VARIATION
    ) {
      variations[productAttributes.variation_name4] = productAttributes.variation_value4
    }
  }
  return variations
}
