<template>
  <v-form @submit.prevent="submit">
    <div id="hub_selector_site_input_wrapper">
      <v-select
          id="hub_selector_site_input"
          v-model="selectedSite"
          :items="sites"
          :label="$t('site')"
          item-text="name"
          item-value="id"
          :loading="loadingSites"
          :disabled="loadingSites"
      ></v-select>
    </div>
    <div id="hub_selector_hub_input_wrapper">
      <v-select
          id="hub_selector_hub_input"
          :disabled="!hubsEnabled"
          v-model="selectedHub"
          :items="filteredHubs"
          :label="$t('hub')"
          :loading="loadingHubs"
          item-text="hub_number"
          item-value="hub_number"
      ></v-select>
    </div>
    <v-btn
        id="hub_selector_submit_btn"
        :disabled="!selectedHub"
        color="secondary"
        @click="submit"
        type="submit"
    >
      {{ $t('select_hub') }}
    </v-btn>
  </v-form>
</template>

<script>
import {api} from "@/bootstrap/api"
import auditLog from "@/bootstrap/auditLog";

export default {
  name: 'HubSelector',
  data: () => ({
    sites: [],
    hubs: [],
    selectedSite: null,
    selectedHub: null,
    loadingSites: true,
    loadingHubs: false
  }),
  created() {
    this.resolveSites()
  },
  watch: {
    selectedSite() {
      this.resolveHubs()
    }
  },
  computed: {
    hubsEnabled() {
      return this.selectedSite && !this.loadingHubs
    },
    filteredHubs() {
      return this.hubs.filter((hub) => hub.site_id === this.selectedSite)
    }
  },
  methods: {
    async resolveSites() {
      this.loadingSites = true
      const results = await api('sites', {
        handleError: false,
        params: {
          user_id: this.$store.getters['core/getAuthUserId']
        }
      })
      this.sites = results.data
      if (this.$store.state.core.authUser.site_id) {
        this.selectedSite = this.$store.state.core.authUser.site_id
      }
      this.loadingSites = false
    },
    async resolveHubs() {
      this.loadingHubs = true
      const results = await api('hubs', {
        handleError: false,
        params: {
          user_id: this.$store.getters['core/getAuthUserId'],
          site_id: this.selectedSite
        }
      })
      this.hubs = results.data
      this.loadingHubs = false
    },
    submit() {
      this.loading = true
      auditLog.logHubChange(this.selectedHub)
      this.$store.dispatch('core/handleHubSelect', this.selectedHub)
      this.$store.dispatch('core/redirect', { name: 'orders' })
      this.$store.commit('cycleCounting/resetState')
    }
  }
}
</script>
