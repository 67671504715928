import { datadogRum } from '@datadog/browser-rum'
import {
    DATADOG_RUM_CLIENT_TOKEN,
    DATADOG_RUM_APPLICATION_ID,
    STAGE,
    API_BASE_URL,
    ENABLE_DATADOG_RUM
} from '@/bootstrap/config'
import { version, name } from '@/../package'

class DataDogRumService {
    init(){
        if (!ENABLE_DATADOG_RUM) {
            return
        }
        datadogRum.init({
            applicationId: DATADOG_RUM_APPLICATION_ID,
            clientToken: DATADOG_RUM_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            service: name,
            env: STAGE,
            version,
            sampleRate: 100,
            sessionReplaySampleRate: 100,
            trackInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel:'mask-user-input',
            allowedTracingOrigins: [API_BASE_URL],
        });
        datadogRum.startSessionReplayRecording()
    }
}
export default new DataDogRumService()
