import {ENABLE_PENDO, PENDO_KEY, STAGE} from '@/bootstrap/config'
import store from '@/bootstrap/store'
import {datadogLogs} from '@datadog/browser-logs'

export default function registerPendo() {
    if (!ENABLE_PENDO) {
        return
    }
    window.pendoInitializer(PENDO_KEY);
    if (store.getters['core/getAuthUser']) {
        const user = store.getters['core/getAuthUser']
        try {
            pendo.initialize({
                visitor: {
                    id: `${user.id}-${STAGE}`,
                    email: user.email,
                    full_name: user.name
                }
            })
        } catch (error) {
            datadogLogs.logger.error('Failed to initialize Pendo', error as typeof Error)
        }
    }
}
