export const recalledStatuses = {
  ON_HOLD: 'ON_HOLD',
  QUARANTINED: 'QUARANTINED',
} as const;

export const recallStatus = {
  AVAILABLE: 'AVAILABLE',
  ...recalledStatuses,
} as const;

type RecallStatuses = typeof recallStatus;
type RecalledStatuses = typeof recalledStatuses;

export type RecallStatus = RecallStatuses[keyof RecallStatuses];
export type RecalledStatus = RecalledStatuses[keyof RecalledStatuses];
