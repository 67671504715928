import { Stock } from "@/types/Stock";
import { ContainerPickState } from ".";
import mutations from "./mutationTypes";

export default {
  [mutations.START_ORDER](state: ContainerPickState): void {
    state.pickedStock = [];
    state.containerScanValidation = {
      containerTypeValidation: { failed: false },
      containerParentValidation: { failed: false },
      containerStatusValidation: { failed: false },
      containerSkusInOrderValidation: { failed: false },
      quantityValidation: { failed: false },
      batchExpiryValidation: { failed: false },
      batchMinExpiryValidation: { failed: false },
      batchQuarantineValidation: { failed: false },
      previouslyPickedValidation: { failed: false },
      unpickableLocationValidation: { failed: false },
      containerHasBatchValidation: { failed: false },
    };
  },

  [mutations.SET_CONTAINER_SCAN_VALIDATION](
    state: ContainerPickState,
    containerScanValidation: ContainerPickState["containerScanValidation"]
  ) {
    state.containerScanValidation = containerScanValidation;
  },

  [mutations.PICK_CONTAINER_STOCK](state: ContainerPickState, {stock, orderLineId}: {stock: Stock, orderLineId: number}) {
    state.pickedStock.push(
      ({ orderLineId, ...stock })
    );
  },
};
