import {ref, computed, UnwrapRef} from "vue";
import { TaskStatus, TaskTypes } from './types';
import { Challenge } from '../challenges/base-challenge';

export type BaseTask<T, U> = {
  id: number,
  type: TaskTypes,
  status: TaskStatus,
  meta: {
    user: {
      id: number,
      email: string | null,
    } | null,
    timings: {
      start: string | null,
      end: string | null,
    }
  },
  attributes: T,
  challenges: Challenge<U>[],
}

export const useBaseTask = <T, U>() => {
  const state = {
    tasks: ref<BaseTask<T, U>[]>([]),
  }

  const getters = {
   getTasks : computed(() => state.tasks.value),
   getTaskById: computed(() => {
     return (id: number) => state.tasks.value.find((task) => task.id === id)
   }),
  }

  function reset() {
    state.tasks.value = [];
  }

  function setBaseTasks(newTasks: BaseTask<T, U>[]): void {
    state.tasks.value = newTasks as unknown as UnwrapRef<BaseTask<T, U>>[];
  }

  function setStartTimeOnAllTasks(): void {
    const now = new Date().toString();
    state.tasks.value.forEach((task) => {
      task.meta.timings.start = now;
      task.status = TaskStatus.IN_PROGRESS;
    })
  }

  function removeTaskById(taskId: number): void {
    const idx = state.tasks.value.findIndex(t => t.id === taskId);
    if (idx >= 0) {
      state.tasks.value.splice(idx, 1);
    }
  }

  const actions = {
    setBaseTasks,
    setStartTimeOnAllTasks,
    removeTaskById,
    reset
  }

  return {
    state,
    actions,
    getters
  }
}
