<template>
    <v-dialog max-width="500"
              v-model="shouldDisplay"
              persistent
    >
      <v-card>
        <v-form>
          <v-card-title class="headline">
            <p>
              {{ $t('failed_to_find_order') }}
            </p>
            <p>
              {{ $t('confirm_that_the_scanned_order_number_is_correct') }}
            </p>
            <div>
              <span>{{ $t('order_number') + ' ' }} </span>
              <span class="font-weight-bold">{{ barcodeNumber }} </span>
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn
                id="yes_submit_btn"
                type="button"
                color="secondary"
                @click="confirm"
            >
              {{ $t('yes') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                id="no_submit_btn"
                type="button"
                @click="close"
            >
              {{ $t('no') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  data: () => ({
    shouldDisplay: true
  }),
  props: {
    barcodeNumber: {
      type: String,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    },
  }
}
</script>
