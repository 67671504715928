<template>
  <div>
    <div class="d-sm-flex align-center">
      <h3 class="grow mb-3">
        {{ $t('packing_slip_required') }}
      </h3>
      <v-btn
          color="accent"
          class="mb-3"
          @click="printInsert"
      >
        {{ $t('reprint_packing_slip') }}
      </v-btn>
    </div>
    <v-divider class="mb-3"/>
    <h2 class="font-weight-medium mb-4 text-center text-decoration-underline">
      {{ $t('scan_packing_slip_confirm') }}
    </h2>
    <h2 class="text-center font-weight-light">
      {{ $t('or') }}
    </h2>
    <v-form @submit.prevent="validate">
      <v-text-field
          id="insert_confirmation_barcode_input"
          :label="$t('enter_packing_slip_barcode')"
          v-model="insertBarcode"
          :rules="[v => !!v]"
          :error-messages="errorMessages"
          @input="validateCheckCodeLazy"
      />
      <div class="text-center">
        <v-btn
            id="insert_confirmation_submit_input"
            type="submit"
            color="secondary"
            @click="validate"
            :disabled="!insertBarcode"
        >
          {{ $t('submit') }}
        </v-btn>
      </div>
    </v-form>
    <v-dialog
        v-model="printLoading"
        hide-overlay
        persistent
        max-width="500"
    >
      <v-card
          color="info"
          dark
      >
        <v-card-text class="pt-4">
          <div class="text-center">
            {{ $t('printing_packing_slip') }}
          </div>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {bus} from "@/bootstrap/bus"
import {
  validationRequired,
  isValidBarcode,
  printGenericInsert
} from '@/utilities/insert'
import {isPackageIdentifier} from "@/utilities/CodeIdentifier";

export default {
  name: "Packing",
  data: () => ({
    insertBarcode: null,
    errorMessages: [],
    printLoading: false
  }),
  components: {},
  computed: {
    getInsertConfirmationValues () {
      return this.$store.getters['picking/getInsertConfirmationValues']
    }
  },
  created () {
    bus.$on('barcodeScanned', this.handleBarcodeScan)
    if (this.printOnLoad) {
      this.printInsert()
    }
  },
  beforeDestroy () {
    bus.$off('barcodeScanned', this.handleBarcodeScan)
  },
  props: {
    printOnLoad: {
      required: false,
      type: Boolean,
      default: true
    }
  },
  methods: {
    async printInsert () {
      this.printLoading = true
      try {
        const confirmationValueArray = await printGenericInsert(this.$store.getters['picking/getOrderId'], this.$store.state.core.hubId);
        confirmationValueArray.push(this.$store.getters['picking/getShipmentId']);
        this.$store.commit('picking/setInsertConfirmationValues', confirmationValueArray);
      } catch (e) {
        this.printLoading = false;
      }
      this.printLoading = false;
    },
    handleBarcodeScan (barcode) {
      if (isPackageIdentifier(barcode)) {
        this.$emit('updatePackageIdentifier', barcode);
        return;
      }
      this.insertBarcode = barcode
      this.validate()
    },
    validateCheckCodeLazy () {
      if (validationRequired(this.getInsertConfirmationValues, this.insertBarcode)) {
        this.validate()
      }
    },
    validate () {
      if (
          isValidBarcode(this.getInsertConfirmationValues, this.insertBarcode)
      ) {
        this.$store.commit('picking/setInsertBarcode', this.insertBarcode);
        this.$store.dispatch('picking/transitionState');
      }
      this.errorMessages = [this.$t('incorrect_packing_slip_barcode')];
    },
  }
}
</script>
