
import Vue, {PropType} from 'vue';
import {OrderItem} from '@/types/OrderItem';
import {BundleContent} from '@/types/BundleContent';

export default Vue.extend({
  name: "PackingContents",
  props: {
    orderItems: Array as PropType<OrderItem[]|BundleContent[]>,
  }
})
