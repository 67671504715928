<template>
  <div>
    <v-snackbar
        v-for="(notification, notificationIndex) in notifications"
        :key="notificationIndex"
        :value="1"
        timeout="-1"
        :color="notificationColor(notification)"
    >
      <div class="d-flex align-center">
        <v-icon class="mr-3">
          mdi-alert-outline
        </v-icon>
        <div class="flex-shrink-1">
          <p class="ma-0 notification-message">
            {{ notification.message }}
          </p>
        </div>
      </div>
      <div slot="action">
        <v-btn id="error_close_btn" text @click="removeNotification(notification)">
          {{ $t('close') }}
        </v-btn>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import NotificationTypes from "@/enums/NotificationTypes";

export default {
  name: 'NotificationsBar',
  data: () => ({}),
  computed: {
    notificationColor () {
      return function (notification) {
        switch (notification.type) {
          case NotificationTypes.NOTIFICATION_TYPE_SUCCESS:
            return 'success';
          case NotificationTypes.NOTIFICATION_TYPE_ERROR:
            return 'red';
          case NotificationTypes.NOTIFICATION_TYPE_INFO:
            return 'info';
        }
      }
    },
    notifications () {
      return this.$store.state.core.notifications
    },
  },
  methods: {
    removeNotification (notification) {
      this.$store.commit('core/removeNotification', notification)
    }
  }
}
</script>
