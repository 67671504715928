import { StockForLocation } from '@/types/Stock';
import { CountEvent } from "@/types/StockCounts";
import { CycleCountingStateInterface } from '.';
import { groupCountsBySkuAndBatch } from '@/utilities/groupCounts';

export const doBatchMatchIfPresent = (countEvent: CountEvent, stock: StockForLocation): boolean => {
  if(!stock.attributes.batch && !countEvent.batch_id) {
    return true;
  }
  return stock.attributes.batch?.id === countEvent.batch_id;
}


export const isRecountNeeded = (state: CycleCountingStateInterface): boolean => {
  // if this is already a recount then don't do it again
  if(state.isRecount) {
    return false;
  }

  const allCounts = groupCountsBySkuAndBatch(state.countEvents, state.stocks, state.activeTaskId);
  const margin = getMarginForActiveTask(state)

  return Object.values(allCounts).some(value =>
    Math.abs(value.counted_quantity - value.system_quantity) > margin
  );
}

export const skuHasBatchedCount = (state: CycleCountingStateInterface, sku: number|undefined): boolean|null => {
  if (state.stocks.length === 0 || sku === undefined) {
    return null;
  }
  const filtered = state.stocks.filter( (item) => item.attributes.sku === sku )
  if (filtered.length === 0) {
    return null;
  }
  return !!filtered[0].attributes.batch
}

export const getMarginForActiveTask = (state: CycleCountingStateInterface): number => {
  return state.countTasks.filter((task) => task.id === state.activeTaskId)[0]?.discrepancy_margin ?? 0
}
