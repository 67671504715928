<template>
  <v-dialog
      :value="true"
      @input="$emit('close')"
      max-width="500"
  >
    <v-card>
      <v-form @submit.prevent="validate" ref="form">
        <v-card-title class="headline">
          {{ $t('confirm_item') }}
        </v-card-title>
        <v-card-text>
          <v-select
              id="item_confirmation_dialog_reason_input"
              ref="reasonInput"
              required
              v-model="reason"
              :items="reasonOptions"
              :label="$t('reason_manual_pick')"
              :rules="[v => !!v]"
              :error-messages="reasonErrorMessages"
              @input="clearMessages"
          />
          <v-text-field
              id="item_confirmation_three_digit_check_code_input"
              :label="$t('enter_3_digit_check_code')"
              v-model="checkCode"
              :disabled="checkCodeDisabled"
              type="number"
              :error-messages="checkCodeErrorMessages"
              ref="checkCodeInput"
              @input="clearMessages"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              id="item_confirmation_dialog_close_btn"
              color="accent"
              @click="$emit('close')"
          >
            {{ $t('close') }}
          </v-btn>
          <v-btn
              id="item_confirmation_dialog_submit_btn"
              type="submit"
              color="secondary"
              @click="submit"
          >
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationChallenge',
  components: {},
  data: () => ({
    enabledConfirmationCodeReasons: [
      'no_barcode',
      'barcode_wont_scan'
    ],
    reasonErrorMessages: [],
    checkCodeErrorMessages: [],
    checkCode: null,
    reason: null,
  }),
  computed: {
    checkCodeDisabled() {
      return !this.enabledConfirmationCodeReasons.includes(this.reason);
    },
    reasonOptions() {
      return [
        { value: 'no_barcode', text: this.$t('no_barcode') },
        { value: 'no_3_digit_code', text: this.$t('no_3_digit_code') },
        { value: 'barcode_wont_scan', text: this.$t('barcode_wont_scan') },
      ]
    },
  },
  props: {
    checkCodeRequired: {
      required: true,
      type: [Number, Boolean, String],
    }
  },
  methods: {
    validate() {
      if (this.reason == null) {
        this.reasonErrorMessages = [this.$t('reason_required')]
        return false;
      }

      if (!this.checkCodeDisabled) {
        if (!this.checkCode) {
          this.checkCodeErrorMessages = [this.$t('three_digit_check_code_required')]
          return false;
        }

        if (!this.validateCheckCodeStrict()) {
          this.checkCodeErrorMessages = [this.$t('incorrect_three_digit_check_code')]
          return false;
        }
      }

      return true;
    },
    submit() {
      if (!this.validate()) {
        return;
      }

      this.$emit('pass', this.reason)
    },
    clearMessages() {
      this.reasonErrorMessages = [];
      this.checkCodeErrorMessages = [];
    },
    validateCheckCodeStrict() {
      return this.checkCode && this.checkCode.toString() === this.checkCodeRequired.toString()
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.reasonInput.$refs.input.focus()
      })
    })
  }
}
</script>
